export const SvgInsta = () => {
  return (
    <svg
    style={{background:"transparent"}}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill="white" fill-opacity="0.29" />
      <path
        d="M18.1425 7.5H11.8575C9.1275 7.5 7.5 9.1275 7.5 11.8575V18.135C7.5 20.8725 9.1275 22.5 11.8575 22.5H18.135C20.865 22.5 22.4925 20.8725 22.4925 18.1425V11.8575C22.5 9.1275 20.8725 7.5 18.1425 7.5ZM15 17.91C13.395 17.91 12.09 16.605 12.09 15C12.09 13.395 13.395 12.09 15 12.09C16.605 12.09 17.91 13.395 17.91 15C17.91 16.605 16.605 17.91 15 17.91ZM19.44 11.16C19.4025 11.25 19.35 11.3325 19.2825 11.4075C19.2075 11.475 19.125 11.5275 19.035 11.565C18.945 11.6025 18.8475 11.625 18.75 11.625C18.5475 11.625 18.36 11.55 18.2175 11.4075C18.15 11.3325 18.0975 11.25 18.06 11.16C18.0225 11.07 18 10.9725 18 10.875C18 10.7775 18.0225 10.68 18.06 10.59C18.0975 10.4925 18.15 10.4175 18.2175 10.3425C18.39 10.17 18.6525 10.0875 18.8925 10.14C18.945 10.1475 18.99 10.1625 19.035 10.185C19.08 10.2 19.125 10.2225 19.17 10.2525C19.2075 10.275 19.245 10.3125 19.2825 10.3425C19.35 10.4175 19.4025 10.4925 19.44 10.59C19.4775 10.68 19.5 10.7775 19.5 10.875C19.5 10.9725 19.4775 11.07 19.44 11.16Z"
        fill="white"
      />
    </svg>
  );
};
