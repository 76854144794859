import { visaDetail, visaDetailError, visaDetailSuccess } from "../Reducers/VisaDetail"
import { AppDispatch } from "../store"
import { $api } from "../../componets/api"

export const fetchingVisaDetail = (id: number | any)=>{
    return async(dispacth : AppDispatch)=>{
        try{
            dispacth(visaDetail())

            const response = await $api.get(`country_visa/list/${id}/`)
            dispacth(visaDetailSuccess(response.data))
            

        }catch(error: any){
            dispacth(visaDetailError(error.message))
        }
    }
}