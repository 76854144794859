export const SvgAboutUsBlock_1 = () => {
  return (
    <svg
      width="133"
      height="120"
      viewBox="0 0 133 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_331_910)">
        <path
          d="M66 120C99.1371 120 126 93.1371 126 60C126 26.8629 99.1371 0 66 0C32.8629 0 6 26.8629 6 60C6 93.1371 32.8629 120 66 120Z"
          fill="url(#paint0_linear_331_910)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.48108 67.6342C19.0328 64.1779 33.278 64.8385 49.2168 69.6161C76.9611 77.9326 97.392 53.9325 120.765 69.6161C122.145 70.5422 123.456 71.5032 124.696 72.499C118.946 99.637 94.8512 120 66.0001 120C35.4491 120 10.2312 97.1667 6.48108 67.6342H6.48108Z"
          fill="url(#paint1_linear_331_910)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.62879 80.5972C20.0582 73.7199 30.6555 72.0883 41.4207 75.7025C58.1088 81.3052 67.3076 68.1473 89.9492 81.3052C105.704 90.4609 114.071 79.0158 122.502 80.2371C114.199 103.417 92.0356 120 65.9999 120C40.0992 120 18.0317 103.589 9.62878 80.5972L9.62879 80.5972Z"
          fill="url(#paint2_linear_331_910)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.70593 77.869C40.7413 73.7759 38.9239 86.3089 72.7069 90.6406C93.7677 93.3411 110.927 86.7271 120.989 84.0422C111.721 105.211 90.5876 120 66.0002 120C39.0861 120 16.311 102.279 8.70593 77.869Z"
          fill="url(#paint3_linear_331_910)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.1617 106.567C26.9884 105.612 25.8519 104.614 24.7545 103.575C28.199 102.086 38.9593 101 51.7001 101C67.2193 101 79.8001 102.612 79.8001 104.6C79.8001 106.588 67.2193 108.2 51.7001 108.2C41.8469 108.2 33.1782 107.55 28.1617 106.567Z"
          fill="url(#paint4_linear_331_910)"
        />
        <path
          opacity="0.2"
          d="M112.8 42.9999C123.735 42.9999 132.6 34.1351 132.6 23.1999C132.6 12.2647 123.735 3.3999 112.8 3.3999C101.865 3.3999 93 12.2647 93 23.1999C93 34.1351 101.865 42.9999 112.8 42.9999Z"
          fill="url(#paint5_linear_331_910)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.55286 51.8211C10.5421 22.5532 35.6373 0 66 0C77.814 0 88.8304 3.41441 98.1165 9.31026C92.1384 12.6159 87.8801 17.2136 85.3414 23.1031C79.3711 36.9539 68.6404 44.256 48.3225 39.6533C32.0692 35.9714 16.0009 41.1485 6.55286 51.8211Z"
          fill="url(#paint6_linear_331_910)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.03613 49.1837C1.7081 50.0307 2.69109 50.711 3.98512 51.2247C5.27915 51.7384 6.17725 51.7384 6.67944 51.2247C6.6982 52.138 7.21585 52.8001 8.23237 53.2112C9.75714 53.8277 23.1737 58.5233 25.3154 56.5767C25.3793 57.452 26.6314 58.1595 29.0718 58.6991C32.7324 59.5086 48.4554 62.7553 49.5961 60.7272C50.0653 61.7665 51.1081 62.3806 52.7243 62.5696C55.1486 62.8531 67.4665 64.4053 68.8843 62.2988C69.2738 62.8561 69.9118 63.1348 70.7984 63.1348C72.1282 63.1348 74.9659 63.1193 75.5314 62.5696C75.9083 62.2031 72.5204 59.1548 65.3676 53.4245L47.528 45.0802L16.8887 41.2236L1.03613 49.1837Z"
          fill="url(#paint7_linear_331_910)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.2603 32.7597C41.323 32.3224 41.7284 32.0187 42.1658 32.0815C42.6031 32.1442 42.9068 32.5496 42.8441 32.987L41.7919 40.3209C41.7292 40.7582 41.3238 41.0619 40.8864 40.9992C40.4491 40.9364 40.1454 40.531 40.2081 40.0937L41.2603 32.7597ZM38.2395 50.5325C38.3157 50.0973 38.7303 49.8063 39.1654 49.8824C39.6007 49.9586 39.8917 50.3732 39.8155 50.8084L30.3483 104.894C30.2721 105.329 29.8575 105.62 29.4223 105.544C28.9871 105.468 28.6961 105.053 28.7722 104.618L38.2395 50.5325Z"
          fill="url(#paint8_linear_331_910)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.58289 46.9013C0.859174 47.5842 0.497314 48.2614 0.497314 48.9329C0.497314 49.9401 0.672905 49.8299 3.39552 49.8299C5.2106 49.8299 9.73904 49.7474 16.9808 49.5824C18.4181 49.5306 19.4781 48.4901 20.1608 46.4608C20.4378 49.2409 21.6411 50.7393 23.7707 50.956C26.9652 51.2809 37.8614 51.6692 45.4809 53.7432C53.1005 55.8172 56.0438 57.8886 57.6781 53.0885C58.001 55.1306 58.9925 56.5086 60.6527 57.2225C62.3128 57.9364 66.9103 59.7054 74.4451 62.5297C75.0566 62.8361 75.5029 62.717 75.7837 62.1724C76.205 61.3555 74.6872 58.4141 73.052 56.244C71.4167 54.0738 65.8944 42.4074 50.1706 37.4897C48.6302 35.7394 46.6899 34.8642 44.3495 34.8642C40.839 34.8642 36.938 32.1508 32.9109 34.3127C27.3084 34.058 22.392 34.9282 18.1618 36.9232C13.9316 38.9182 8.40529 42.2443 1.58289 46.9013Z"
          fill="url(#paint9_linear_331_910)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.2685 102.362L48.3336 94.9359C48.185 94.7122 48.2465 94.4109 48.4712 94.2628C48.6958 94.1148 48.9984 94.1761 49.1471 94.3998L54.082 101.826C54.2306 102.05 54.169 102.351 53.9444 102.499C53.7198 102.647 53.4172 102.586 53.2685 102.362Z"
          fill="url(#paint10_linear_331_910)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.7562 105.376C42.575 105.574 42.2666 105.589 42.0672 105.408C41.8679 105.228 41.8531 104.921 42.0342 104.722L52.4029 93.3578C52.5841 93.1593 52.8925 93.1446 53.0919 93.325C53.2912 93.5054 53.306 93.8125 53.1249 94.0111L42.7562 105.376Z"
          fill="url(#paint11_linear_331_910)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.529 93.9253H68.4334C67.4576 96.99 65.6562 98.4777 63.029 98.3886C63.029 98.3886 58.6656 98.3886 49.9387 98.3886L56.529 93.9253Z"
          fill="url(#paint12_linear_331_910)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.9525 81.8413H54.8768C56.1112 91.5046 58.8286 97.0203 63.029 98.3885H49.9387C47.1381 98.0503 45.0987 96.4273 43.8206 93.5195C42.5424 90.6116 41.5864 86.7189 40.9525 81.8413Z"
          fill="url(#paint13_linear_331_910)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.3868 82.7383C54.2397 82.5136 54.3034 82.2127 54.5291 82.0662C54.7547 81.9197 55.0569 81.9831 55.204 82.2078L68.2227 102.099C68.3698 102.323 68.3061 102.624 68.0804 102.771C67.8548 102.917 67.5526 102.854 67.4055 102.629L54.3868 82.7383Z"
          fill="url(#paint14_linear_331_910)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58.0181 105.44C57.837 105.639 57.5285 105.654 57.3292 105.473C57.1298 105.293 57.115 104.986 57.2962 104.787L67.6648 93.4228C67.846 93.2242 68.1544 93.2095 68.3538 93.3899C68.5531 93.5703 68.5679 93.8775 68.3868 94.076L58.0181 105.44Z"
          fill="url(#paint15_linear_331_910)"
        />
        <path
          opacity="0.2"
          d="M112.8 42.9999C123.735 42.9999 132.6 34.1351 132.6 23.1999C132.6 12.2647 123.735 3.3999 112.8 3.3999C101.865 3.3999 93 12.2647 93 23.1999C93 34.1351 101.865 42.9999 112.8 42.9999Z"
          fill="url(#paint16_linear_331_910)"
        />
        <path
          d="M112.8 37.2002C120.532 37.2002 126.8 30.9322 126.8 23.2002C126.8 15.4682 120.532 9.2002 112.8 9.2002C105.068 9.2002 98.8 15.4682 98.8 23.2002C98.8 30.9322 105.068 37.2002 112.8 37.2002Z"
          fill="url(#paint17_linear_331_910)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_331_910"
          x1="37.0203"
          y1="-4.28533"
          x2="1.05205"
          y2="42.0159"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C1FFF7" />
          <stop offset="1" stopColor="#93E9DE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_331_910"
          x1="72.413"
          y1="80.5882"
          x2="60.4662"
          y2="96.9494"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A93EA" />
          <stop offset="1" stop-color="#3768B4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_331_910"
          x1="52.8771"
          y1="87.4034"
          x2="43.4714"
          y2="100.901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A93EA" />
          <stop offset="1" stop-color="#3768B4" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_331_910"
          x1="59.0419"
          y1="93.7689"
          x2="57.2794"
          y2="100.083"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9BB49" />
          <stop offset="1" stop-color="#F08523" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_331_910"
          x1="71.0875"
          y1="108.2"
          x2="71.0875"
          y2="101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E87E1E" stop-opacity="0.01" />
          <stop offset="1" stop-color="#F07023" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_331_910"
          x1="98.8004"
          y1="1.90548"
          x2="95.8115"
          y2="29.9047"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9BB49" />
          <stop offset="1" stop-color="#F08523" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_331_910"
          x1="69.3919"
          y1="22.737"
          x2="58.0116"
          y2="3.50238"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D3FFFA" />
          <stop offset="1" stop-color="#B7FFF6" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_331_910"
          x1="40.2231"
          y1="53.4054"
          x2="40.612"
          y2="51.2068"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5A93EA" />
          <stop offset="1" stop-color="#3768B4" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_331_910"
          x1="41.0297"
          y1="82.0944"
          x2="37.4385"
          y2="27.4434"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB593D" />
          <stop offset="1" stop-color="#F88461" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_331_910"
          x1="34.4721"
          y1="44.4436"
          x2="33.4425"
          y2="49.94"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#77EBFF" />
          <stop offset="1" stop-color="#4D86DE" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_331_910"
          x1="53.1963"
          y1="99.406"
          x2="51.7258"
          y2="93.3083"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB593D" />
          <stop offset="1" stop-color="#F88461" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_331_910"
          x1="51.0996"
          y1="100.319"
          x2="48.2372"
          y2="91.235"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB593D" />
          <stop offset="1" stop-color="#F88461" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_331_910"
          x1="59.426"
          y1="96.3393"
          x2="59.5099"
          y2="95.9539"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5A93EA" />
          <stop offset="1" stop-color="#3768B4" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_331_910"
          x1="46.1362"
          y1="84.1336"
          x2="45.1834"
          y2="93.9664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#77EBFF" />
          <stop offset="1" stop-color="#4D86DE" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_331_910"
          x1="66.0605"
          y1="95.0858"
          x2="62.5782"
          y2="79.9354"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB593D" />
          <stop offset="1" stop-color="#F88461" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_331_910"
          x1="66.3616"
          y1="100.384"
          x2="63.4991"
          y2="91.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB593D" />
          <stop offset="1" stop-color="#F88461" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_331_910"
          x1="98.8004"
          y1="1.90548"
          x2="95.8115"
          y2="29.9047"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9BB49" />
          <stop offset="1" stop-color="#F08523" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_331_910"
          x1="102.901"
          y1="8.14353"
          x2="100.788"
          y2="27.941"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9BB49" />
          <stop offset="1" stop-color="#F08523" />
        </linearGradient>
        <clipPath id="clip0_331_910">
          <rect
            width="132.103"
            height="120"
            fill="white"
            transform="translate(0.497314)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
