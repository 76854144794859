import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { IVisaDetail } from "../../types/VisaDetail"

interface IvisaDetailstate {
    visaDetail : any,
    loader :boolean,
    error : null | string
}

const initialState : IvisaDetailstate = {
    visaDetail : {},
    loader : false,
    error : ''
}

export const visaDetailSlice = createSlice({
    name: '/visaDetail/',
    initialState,
    reducers:{
        visaDetail (state){
            state.loader = true
        },
        visaDetailSuccess (state, action: PayloadAction<any>){
            state.loader = false
            state.visaDetail = action.payload
            state.error = ''
        },
        visaDetailError (state, action: PayloadAction<string>){
            state.loader = false
            state.visaDetail = []
            state.error = action.payload
        },
    }
})

export default visaDetailSlice.reducer
export const {visaDetail, visaDetailSuccess, visaDetailError} = visaDetailSlice.actions