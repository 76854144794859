import { Box, Heading } from "@chakra-ui/react";
import { SvgPostSucces } from "../../assests/svg/ok";

const SuccessBooking = () => {
  return (
    <Box 
    bg='#5551F8'
    backdropFilter="blur(10px)"
    display={{xl:"flex",md:"flex",lg:"flex",sm:"block", base:"block"}}
    gap='500px'
    >
        <Heading
          bg="transparent"
          fontFamily="SF Pro Text regular"
          color="white"
          fontSize="24px"
          fontWeight="500"
          letterSpacing='-0.06px'
          lineHeight='28px'
          w={{xl:"267px", lg : "267px", md:"267px", sm:"220px", base: "220px"}}
        >
          Ваша заявка успешно отправлена!
        </Heading>
        <Heading
          bg="transparent"
          color="white"
          fontFamily="SF Pro Text regular"
          fontSize="14px"
          fontWeight="400"
          mt="20px"
          display='flex'
          gap='5px'
          alignItems='center'
          lineHeight='25px'
          letterSpacing='-0.05px'
        >
          Скоро мы с вами свяжемся. <SvgPostSucces/>
        </Heading>
    </Box>
  );
};

export default SuccessBooking;
