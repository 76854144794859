import "./App.css";
import { DetailTours } from "./pages/detail/Detail";
import { MainPage } from "./pages/main/Main";
import { Route, Routes } from "react-router-dom";
import { VisaDetail } from "./pages/visa/Visa";
import { useEffect, useState } from "react";
import { Loader } from "./componets/loader/Loader";
import { Layout } from "./componets/Layout/Layout";
import { useAppSelector } from "./hooks/toursSelector";
import { Box } from "@chakra-ui/react";
import { Error } from "./componets/error505/Error505";

function App() {
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    const delay = setTimeout(() => {
      setLoader(false);
    }, 1000);
    return () => clearTimeout(delay);
  }, []);

  const {error} = useAppSelector((s)=> s.ToursSlice)
  return (
  <Box>
    {
      loader ? 
      <Loader/>
      :
      <Box>
     
      {error ? (
      <Error/>
      ) : ( 
        <Layout>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route
          path="/:title/detail-tours/:toursId"
          element={<DetailTours />}
        />
        <Route path="/detail-visa/:visaId" element={<VisaDetail />} />
      </Routes>
    </Layout>
 
      )} 
  </Box>
    }
  
  </Box>
  );
}

export default App;
