export const Svg_visa_whatsapp = () => {
  return (
    <svg
      width="119"
      height="119"
      viewBox="0 0 119 119"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path opacity="0.58" d="M119 0H0V119H119V0Z" fill="white" />
      <path
        opacity="0.4"
        d="M34.4109 102.29C41.7989 106.604 50.3768 109.083 59.5001 109.083C87.4155 109.083 110.62 84.4401 108.984 56.5742C107.298 27.8159 81.1679 5.65213 51.0709 10.6105C30.345 14.0317 13.7347 30.8405 10.5117 51.5663C8.62757 63.5655 11.1068 74.92 16.5114 84.2913L12.0984 100.703C11.1068 104.422 14.5279 107.794 18.1971 106.753L34.4109 102.29Z"
        fill="#50C76A"
      />
      <path
        d="M84.2918 75.2176C84.2918 76.1101 84.0935 77.0522 83.6472 77.9447C83.201 78.8372 82.6557 79.6801 81.9616 80.4735C80.722 81.8122 79.3832 82.8039 77.8957 83.3989C76.4082 84.0435 74.7717 84.341 73.0363 84.341C70.5076 84.341 67.7805 83.746 64.9542 82.5064C62.0784 81.2668 59.2523 79.6305 56.426 77.5976C53.5502 75.5151 50.8726 73.1847 48.2943 70.656C45.7159 68.0776 43.4353 65.3505 41.3528 62.5243C39.3199 59.698 37.6834 56.8718 36.4934 54.0455C35.3034 51.2193 34.7085 48.4922 34.7085 45.9139C34.7085 44.228 35.006 42.5918 35.601 41.1043C36.196 39.5672 37.1381 38.1789 38.4769 36.9393C40.0635 35.3526 41.7989 34.6089 43.6334 34.6089C44.3276 34.6089 45.0219 34.7576 45.6665 35.0551C46.3111 35.3526 46.9059 35.7989 47.3522 36.4434L53.0544 44.6247C53.5006 45.2693 53.8478 45.8147 54.0461 46.3601C54.294 46.9055 54.3929 47.4014 54.3929 47.8972C54.3929 48.4922 54.1947 49.0872 53.8476 49.6822C53.5005 50.2772 53.0544 50.8722 52.4594 51.4672L50.5752 53.4505C50.2777 53.748 50.1785 54.0456 50.1785 54.4422C50.1785 54.6406 50.2282 54.8389 50.2777 55.0372C50.3769 55.2355 50.4264 55.3843 50.476 55.533C50.9222 56.3759 51.7157 57.4172 52.8065 58.7064C53.9469 59.9955 55.1369 61.3343 56.426 62.6234C57.7648 63.9622 59.0539 65.1522 60.3926 66.2926C61.6818 67.3835 62.7727 68.1272 63.6157 68.5735C63.7644 68.623 63.913 68.7222 64.0617 68.7718C64.2601 68.871 64.4584 68.871 64.7064 68.871C65.1526 68.871 65.4502 68.7222 65.7477 68.4247L67.6319 66.5405C68.2765 65.8959 68.8714 65.4497 69.4168 65.1522C70.0118 64.8051 70.5572 64.6068 71.2018 64.6068C71.6976 64.6068 72.1934 64.7059 72.7388 64.9539C73.2842 65.2018 73.8794 65.4993 74.4744 65.9456L82.7051 71.7964C83.3497 72.2426 83.7961 72.7881 84.0936 73.3831C84.1432 73.9285 84.2918 74.5234 84.2918 75.2176Z"
        fill="#50C76A"
      />
    </svg>
  );
};
