export const SvgTabProgram = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1997 10.49L13.2297 8.52005L10.0197 5.31005C9.33969 4.64005 8.17969 5.12005 8.17969 6.08005V12.31V17.92C8.17969 18.88 9.33969 19.36 10.0197 18.68L15.1997 13.5C16.0297 12.68 16.0297 11.32 15.1997 10.49Z"
        fill="#FC9011"
      />
    </svg>
  );
};
