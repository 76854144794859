export const SvgTabTelegram = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.2526 6.478C24.3132 5.61941 26.3804 7.68663 25.5218 9.74722L19.5983 23.9637C18.4399 26.744 14.365 26.274 13.8699 23.3031L13.1308 18.869L8.69676 18.13C5.72577 17.6348 5.25584 13.56 8.03611 12.4015L22.2526 6.478ZM23.6757 8.97799C23.8474 8.56587 23.4339 8.15243 23.0218 8.32414L8.80534 14.2477C7.87858 14.6338 8.03523 15.9921 9.02555 16.1571L13.4596 16.8962C14.3026 17.0367 14.9631 17.6972 15.1036 18.5402L15.8426 22.9743C16.0077 23.9646 17.366 24.1212 17.7521 23.1945L23.6757 8.97799Z"
        fill="#B1B5C3"
      />
    </svg>
  );
};
