import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ITours } from "../../types/tours";

interface IToursState {
    tours : ITours[] ,
    loader: boolean,
    error : null | string,
    result: ITours[]
}


const initialState: IToursState = {
    tours : [],
    loader : false,
    error : '',
    result: []

}

export const ToursSlice = createSlice({
    name : 'tours',
    initialState,
    reducers:{
        toursFetching(state){
            state.loader = true
        },
        toursFetchingSuccess(state,action: PayloadAction<ITours[]>){
            state.loader = false
            state.tours = action.payload
            state.result = action.payload
            state.error = ''
        },
        toursFetchingError(state,action:PayloadAction<string>){
            state.loader = false
            state.tours = []
            state.error = action.payload
        },
        filterToursCountry(state, action : PayloadAction<string>){
            state.result = state.tours.filter(el => el.place_to.country === action.payload)
        },
        toursDay (state, action : PayloadAction<string>){
            switch(action.payload) { 
                case 'На 7 дней': { 
                    state.result = state.tours.filter((el)=> el.night === 7)
                   break; 
                } 
                case 'Горящие туры' : {
                    state.result = state.tours.filter(el => el.status === 'hot')
                    break; 
                }

                case 'Пляжный тур' : {
                    state.result = state.tours.filter(el => el.status === 'popular')
                    break;
                }
                case "Цены: по убыванию" :{
                    state.result = state.tours.sort((a,b) => b.price - a.price)
                    break;
                }
                case "Цены: по возрастанию" :{
                    state.result = state.tours.sort((a,b) => a.price - b.price)
                    break;
                }
                case 'Экскурсионные туры' : {
                    state.result = state.tours.filter(el => el.excursion === 'excursion')
                    break;
                }
            
                default: { 
                   return state
                } 
             } 
        }
    }
});


export default ToursSlice.reducer;
export const {toursFetching, toursFetchingSuccess, toursFetchingError, toursDay, filterToursCountry} = ToursSlice.actions;