import {
  Box,
  Container,
  Heading,
  Image
} from "@chakra-ui/react";
import { SvgWatsapp } from "../../assests/svg/SvgWatsapp";
import { SvgTelegram } from "../../assests/svg/SvgTelgram";
import Form from "../ticketsPost.tsx/postTour";

export const Tickets = () => {
  return (
    <Container id="tickets" maxW="1195px" mt="216px" mb="35px">
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Heading
            as="h4"
            color="#2F2A23"
            fontSize="48px"
            fontFamily="SF Pro Text"
            fontWeight="700"
          >
            Авиабилеты
          </Heading>

          <Heading
            as="h6"
            color="#FCFCFD"
            p="2px"
            fontFamily="Poppins, sans-serif"
            fontWeight="500"
            fontSize="16px"
            bg="#92A5EF"
            mt="35px"
            width="42px"
            height="24px"
            borderRadius="100px"
            textAlign="center"
          >
            01
          </Heading>
          <Heading
            as="h4"
            color="#2F2A23"
            fontFamily="SF Pro Text"
            fontSize="24px"
            fontWeight="600"
            mt="20px"
          >
            Бронируйте билеты легко!
          </Heading>
          <Heading
            as="p"
            color="#777E90"
            fontSize="14px"
            fontWeight="500"
            width={{
              xl: "456px",
              lg: "456px",
              md: "456px",
              sm: "359px",
              base: "358px",
            }}
            mt="16px"
            mb="39px"
            lineHeight="24px"
          >
            Мы рады предложить вам фантастическую возможность забронировать
            авиабилеты для ваших предстоящих поездок.
          </Heading>
        </Box>
        <Box>
          <Box
            display={{
              xl: "flex",
              lg: "flex",
              md: "flex",
              sm: "none",
              base: "none",
            }}
            mt="140px"
            mb="10px"
            alignItems="center"
            gap="25px"
          >
            <Heading
              as="h4"
              color="#777E90"
              fontSize="14px"
              fontFamily="Poppins,sans-serif"
              fontWeight="400 "
            >
              <a href="https://t.me/SKYNUR_TRAVEL">Написать в телеграм</a>

            </Heading>{" "}
            <SvgTelegram />
          </Box>
          <Box
            display={{
              xl: "flex",
              lg: "flex",
              md: "flex",
              sm: "none",
              base: "none",
            }}
            alignItems="center"
            gap="14px"
          >
            <Heading
              as="h4"
              color="#777E90"
              fontSize="14px"
              fontFamily="Poppins,sans-serif"
              fontWeight="400 "
            >
              <a href="https://wa.me/996709609030">Написать в Whatsapp</a>

            </Heading>{" "}
            <SvgWatsapp />
          </Box>
        </Box>
      </Box>
      <Box position="relative" mt="40px">
        <Image
          src={require("../../assests/img/tickets.png")}
          borderRadius="20px"
          height="550px"
          display={{
            xl: "block",
            lg: "block",
            md: "block",
            sm: "none",
            base: "none",
          }}
          objectFit='cover'
          maxHeight='550px'
          w="100%"
          alt=""
        />   
         <Form/>
      </Box>

    </Container>
  );
};
