import { useForm, SubmitHandler } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  Input,
  Heading,
  FormErrorMessage,
  Textarea,
  Box,
} from "@chakra-ui/react";
import { useState } from "react";
import Success from "../success/Success";
import { MyData } from "../../types/MyData";
import { useAppDispatch } from "../../hooks/toursDispatch";
import { postBooking } from "../../store/Reducers/book_tickets";
import { PulseLoader } from "react-spinners";

const Form = () => {
  const [success, setSuccess] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,

    formState: { errors, isValid },
  } = useForm<MyData>();

  const onSubmit: SubmitHandler<MyData> = (data) => {
    dispatch(postBooking(data));
    setTimeout(() => {
      setSuccess(true);
    }, 1000);

    setTimeout(() => {
      setSuccess(false);
    }, 5000);
    reset();
  };

  return (
    <Box
    >
      {!success ? (
        <Box 
        position='absolute'
        width={{
          xl: "431px",
          lg: "431px",
          md: "431px",
          sm: "236px",
          base: "236px",
        }}
        pb="24px"
        bg="rgba(255, 255, 255, 0.7)"
        backdropFilter="blur(10px)"
        borderRadius="14px"
        top={{ xl: "26px", lg: "26px", md: "26px", sm: "0", base: "0" }}
        left={{ xl: "46px", lg: "46px", md: "46px", sm: "0", base: "0" }}
        
        >
          <Heading
            as="h4"
            color=" #2F2A23"
            fontSize="24px"
            fontFamily="SF Pro Text"
            fontWeight="500"
            bg="transparent"
            pt="29px"
            ml={{
              xl: "34px",
              lg: "34px",
              md: "34px",
              sm: "15px",
              base: "15px",
            }}
          >
            Оставьте заявку
          </Heading>
          <Heading
            as="h4"
            color="rgba(20, 33, 61, 0.6)"
            fontFamily="Poppins,sans-serif"
            fontWeight="400"
            fontSize="14px"
            mt="5px"
            bg="transparent"
            lineHeight="20px"
            ml={{
              xl: "34px",
              lg: "34px",
              md: "34px",
              sm: "15px",
              base: "15px",
            }}
          >
            Для индивидуальной консультации
          </Heading>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ background: "transparent" }}
          >
            <FormControl
              bg="transparent"
              id="name"
              isInvalid={errors.username ? true : undefined}
            >
              <FormLabel
                bg="transparent"
                color="#5551F8"
                fontSize="12px"
                fontFamily="Poppins,sans-serif"
                mt="15px"
                ml={{
                  xl: "34px",
                  lg: "34px",
                  md: "34px",
                  sm: "15px",
                  base: "15px",
                }}
              >
                Имя
              </FormLabel>
              <Input
                bg="transparent"
                type="text"
                width="364px"
                ml={{
                  xl: "34px",
                  lg: "34px",
                  md: "34px",
                  sm: "15px",
                  base: "15px",
                }}
                color="#777E90"
                outline="none"
                border="none"
                variant="flushed"
                {...register("username", { required: true })}
                placeholder="Введите имя"
              />
              {errors.username && (
                <FormErrorMessage color="red" ml="32px" bg="transparent">
                  Поле "Имя" обязательно
                </FormErrorMessage>
              )}
              <Box
                w={{
                  xl: "364px",
                  lg: "364px",
                  md: "364px",
                  sm: "320px",
                  base: "320px",
                }}
                height="1px"
                color="rgba(20, 33, 61, 0.6)"
                bg="#14213D"
                ml={{
                  xl: "32px",
                  lg: "32px",
                  md: "32px",
                  sm: "15px",
                  base: "15px",
                }}
              ></Box>
            </FormControl>

            <FormControl
              bg="transparent"
              id="phone"
              isInvalid={errors.phone ? true : undefined}
            >
              <FormLabel
                bg="transparent"
                color="#5551F8"
                fontSize="12px"
                fontFamily="Poppins,sans-serif"
                mt="15px"
                ml={{
                  xl: "34px",
                  lg: "34px",
                  md: "34px",
                  sm: "15px",
                  base: "15px",
                }}
              >
                Телефон
              </FormLabel>

              <Input
                bg="transparent"
                width="364px"
                ml={{
                  xl: "34px",
                  lg: "34px",
                  md: "34px",
                  sm: "15px",
                  base: "15px",
                }}
                outline="none"
                border="none"
                variant="flushed"
                type="number"
                {...register("phone", { required: true, pattern: /^\d+$/ })}
                placeholder="Номер телефона"
              />
              {errors.phone?.type === "required" && (
                <FormErrorMessage color="red" bg="transparent" ml="32px">
                  Поле "Номер" обязательно
                </FormErrorMessage>
              )}
              {errors.phone?.type === "pattern" && (
                <FormErrorMessage color="red" bg="transparent" ml="32px">
                  Поле "Номер" должно содержать только цифры
                </FormErrorMessage>
              )}
              <Box
                w={{
                  xl: "364px",
                  lg: "364px",
                  md: "364px",
                  sm: "320px",
                  base: "320px",
                }}
                height="1px"
                bg="#14213D"
                ml={{
                  xl: "32px",
                  lg: "32px",
                  md: "32px",
                  sm: "15px",
                  base: "15px",
                }}
              ></Box>
            </FormControl>

            <FormControl
              bg="transparent"
              id="desc"
              isInvalid={errors.desc ? true : undefined}
            >
              <FormLabel
                bg="transparent"
                color="#5551F8"
                fontSize="12px"
                fontFamily="Poppins,sans-serif"
                mt="15px"
                ml={{
                  xl: "34px",
                  lg: "34px",
                  md: "34px",
                  sm: "15px",
                  base: "15px",
                }}
              >
                Текстовое поле
              </FormLabel>
              <Textarea
                width={{
                  xl: "364px",
                  lg: "364px",
                  md: "364px",
                  sm: "320px",
                  base: "320px",
                }}
                mt="10px"
                px="5px"
                borderRadius="7px"
                height="64px"
                ml={{
                  xl: "34px",
                  lg: "34px",
                  md: "34px",
                  sm: "15px",
                  base: "15px",
                }}
                variant="flushed"
                outline="none"
                border="1px solid rgba(20, 33, 61, 0.6)"
                id="desc"
                {...register("desc")}
              ></Textarea>
            </FormControl>

            <Input
              disabled={!isValid}
              type="submit"
              width={{
                xl: "364px",
                lg: "364px",
                md: "364px",
                sm: "320px",
                base: "320px",
              }}
              height="52px"
              bg="#5551F8"
              borderRadius="8px"
              mt="15px"
              ml={{
                xl: "34px",
                lg: "34px",
                md: "34px",
                sm: "15px",
                base: "15px",
              }}
              color="#FFFFFF"
              fontFamily="SF Pro Text"
              fontWeight="600"
              textAlign="center"
            />
            {success && <PulseLoader color="#7aacf1" speedMultiplier={0.8} />}
          </form>
        </Box>
      ) : (
        <Success />
      )}
    </Box>
  );
};

export default Form;
