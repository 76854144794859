import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { ITours } from "../../types/tours"

interface IDetailTours {
    detailTours : any,
    loader: boolean,
    error: string | null
}

const initialState:IDetailTours = {
    detailTours : {},
    loader: false,
    error : ''
}

export const detailToursSlice = createSlice({
    name: "detailToursSlice",
    initialState,
    reducers:{
        toursDetailFetching(state){
            state.loader = true
        },
        toursDetailFetchingSuccess(state,action : PayloadAction<any>){
            state.loader = false
            state.detailTours = action.payload
            state.error =  ""
        },
        toursDetailFetchingError(state,action : PayloadAction<string>){
            state.loader = false
            state.error = action.payload
            state.detailTours = []
        }
    }
})


export default detailToursSlice.reducer
export const {toursDetailFetching,toursDetailFetchingSuccess, toursDetailFetchingError} = detailToursSlice.actions
