import { Box, Heading, Image } from "@chakra-ui/react";
import { SvgAboutUsBlock_2 } from "../../assests/svg/SvgAboutIUs_block_2";
import { SvgEmail } from "../../assests/svg/SvgEmail";
import { SvgPhone } from "../../assests/svg/SvgPhone";
import { SvgLocation } from "../../assests/svg/SvgLocation";
import { SvgTime } from "../../assests/svg/SvgTime";
import { Svg_visa_phone } from "../../assests/svg/Svg_visa_phone";
import { Svg_visa_whatsapp } from "../../assests/svg/SvgVisa_block_1";
import { useAppDispatch } from "../../hooks/toursDispatch";
import { useAppSelector } from "../../hooks/toursSelector";
import { useEffect } from "react";
import { fetchingVisaDetail } from "../../store/actions/visaDetailAction";
import { useParams } from "react-router-dom";

export const Visa_hero = () => {
  const dispatch = useAppDispatch()
  const {loader, visaDetail ,error} = useAppSelector((s)=> s.visaDetailSlice)
  
const params = useParams()
  useEffect(()=> {
    dispatch(fetchingVisaDetail(params.visaId))
  },[])
  return (
    <Box pt="144px" bg="#F9F9F9">
    
      {error && <Heading bg='transparent'>Something Went Wrong</Heading>}
        <Box  bg="#F9F9F9">
          <Box
            bg="#F9F9F9"
            maxW="1300px"
            mx="auto"
            display="flex"
            gap="36px"
            alignItems="center"
            flexDirection={{
              xl: "row",
              lg: "row",
              md: "row",
              sm: "column-reverse",
              base: "column-reverse",
            }}
          >
            <Box m="0 15px" bg="transparent">
              <Image
                w="706px"
                h={{xl:"473px",lg:"473px",md:"473px",sm:"227px", base:"227px"}}
                rounded="24px"
                src={visaDetail.image}
                alt=""
                bg="#F9F9F9"
              />
            </Box>
            <Box width={{xl:"558px",lg:"558px",md:"558px",sm:"340px",base:"340px"}} display="flex" flexDirection="column" mx='0 20px' gap="28px" bg="#F9F9F9">
              <Heading
                fontFamily="SF Pro Text"
                color="rgba(20, 33, 61, 0.6)"
                fontSize="20px"
                fontWeight="700"
                bg="#F9F9F9"
              >
                {visaDetail.main_title}
              </Heading>
              <Heading
                as="h6"
                color="#FCFCFD"
                p="2px"
                fontFamily="Poppins, sans-serif"
                fontWeight="500"
                fontSize="16px"
                bg="#92A5EF"
                width="42px"
                height="24px"
                borderRadius="100px"
                textAlign="center"
              >
                01
              </Heading>
              <Box display="flex" flexDirection="column" bg="#F9F9F9">
                <Heading
                  fontFamily="SF Pro Text"
                  color="#2F2A23"
                  fontSize={{
                    xl: "48px",
                    lg: "48px",
                    md: "48px",
                    sm: "30px",
                    base: "30px",
                  }}
                  fontWeight="700"
                  bg="#F9F9F9"
                >
                  {visaDetail.title}
                </Heading>
              </Box>
            </Box>
          </Box>

          <Box
            maxW="1300px"
            mx="auto"
            mt="200px"
            w="100%"
            bg="rgba(204, 215, 255, 0.26)"
            rounded={{ xl: "30px", lg: "30px", md: "30px", sm: "0", base: "0" }}
            padding={{
              xl: "85px 209px 85px 90px",
              lg: "85px 209px 85px 90px",
              md: "85px 209px 85px 90px",
              sm: "50px 16px 60px 16px",
              base: "50px 20px 60px 16px",
            }}
          >
            <Heading
              fontFamily="SF Pro Text, regular"
              color="#23262F"
              fontSize={{
                xl: "32px",
                lg: "32px",
                md: "32px",
                sm: "24px",
                base: "24px",
              }}
              fontWeight="600"
              bg="transparent"
            >
              {visaDetail.sub_title}

            </Heading>
            <Heading
              fontFamily="SF Pro Text regular"
              fontSize="24px"
              fontWeight="600"
              color="rgba(20, 33, 61, 0.6)"
              lineHeight="32px"
              w={{
                xl: "540px",
                lg: "540px",
                md: "540px",
                sm: "358px",
                base: "358px",
              }}
              mt="29px"
              mb="106px"
              bg="transparent"
            >
              {visaDetail.description}
            </Heading>
            <Box
             
              display="flex"
              flexWrap="wrap"
              justifyContent="space-between"
              bg="transparent"
            >
               {visaDetail.type?.map((el : any, idx: any) => (
                <Box display="flex" flexDirection="column" bg="transparent">
                  <Heading
                    as="h6"
                    color="#FCFCFD"
                    p="2px"
                    fontFamily="Poppins, sans-serif"
                    fontWeight="500"
                    fontSize="16px"
                    bg="#92A5EF"
                    width="42px"
                    height="24px"
                    borderRadius="100px"
                    textAlign="center"
                  >
                    0{idx + 1}
                  </Heading>
                  <Heading
                    fontFamily="SF Pro Text"
                    fontSize="24px"
                    fontWeight="600"
                    color=" #23262F"
                    lineHeight="32px"
                    mb="14px"
                    bg="transparent"
                  >
                    {el.title}
                  </Heading>
                  <Heading
                    fontFamily="SF Pro Text regular"
                    fontSize="14px"
                    fontWeight="400"
                    color=" #777E90"
                    lineHeight={{
                      xl: "32px",
                      lg: "32px",
                      md: "32px",
                      sm: "24px",
                      base: "24px",
                    }}
                    w={{
                      xl: "403px",
                      lg: "403px",
                      md: "403px",
                      sm: "340px",
                      base: "340px",
                    }}
                 
                    mb="40px"
                    bg="transparent"
                  >
                    {el.description}
                  </Heading>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>


      <Box
        maxW="1120px"
        display={{
          xl: "block",
          lg: "block",
          md: "block",
          sm: "flex",
          base: "flex",
        }}
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        mx="auto"
        pt={{
          xl: "200px",
          lg: "200px",
          md: "200px",
          sm: "135px",
          base: "135px",
        }}
        bg="#F9F9F9"
      >
        <Heading
          color="#23262F"
          fontSize="32px"
          fontWeight="600"
          fontFamily="SF Pro Text"
          pb="53px"
          textAlign="start"
          bg="#F9F9F9"
        >
          Как подать заявку
        </Heading>

        <Box
          bg="#F9F9F9"
          display={{
            xl: "flex",
            lg: "flex",
            md: "flex",
            sm: "block",
            base: "block",
          }}
          textAlign="center"
          alignItems="center"
          justifyContent="space-between"
          gap="40px"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            width={{
              xl: "352px",
              lg: "352px",
              md: "643px",
              sm: "643px",
              base: "352px",
            }}
            height="192px"
            border="2px solid rgba(188, 188, 188, 0.2)"
            borderRadius="17px"
            mb={{md:"0",base:"20px"}}

          >
            <Box bg="transparent">
              <Svg_visa_whatsapp />
            </Box>
            <Box>
              <Heading
                width="136px"
                fontSize="16px"
                color="#23262F"
                fontFamily="SF Pro Text"
                textAlign="start"
              >
                Подать заявку онлайн
              </Heading>
              <Heading
                color="#353945"
                fontSize="12px"
                fontWeight="400"
                fontFamily="Poppins, sans-serif"
                py="7px"
                width="105px"
                height="26px"
                mt="8px"
                bg={"rgba(146, 165, 239, 0.1)"}
                borderRadius="32px"
                textAlign="center"
              >
                <a href="https://wa.me/996709609030">НАПИСАТЬ</a>
              </Heading>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            width={{
              xl: "352px",
              lg: "352px",
              md: "643px",
              sm: "643px",
              base: "352px",
            }}
            height="192px"
            border="2px solid rgba(188, 188, 188, 0.2)"
            borderRadius="17px"
            mb={{md:"0",base:"20px"}}
          >
            <Box bg="transparent">
            <SvgAboutUsBlock_2 />
            </Box>
            <Box>
              <Heading
                width="136px"
                fontSize="16px"
                color="#23262F"
                fontFamily="SF Pro Text"
                textAlign="start"
              >
                Подать заявку онлайн
              </Heading>
              <Heading
                color="#353945"
                fontSize="12px"
                fontWeight="400"
                fontFamily="Poppins, sans-serif"
                py="7px"
                width="105px"
                height="26px"
                mt="8px"
                bg={"rgba(146, 165, 239, 0.1)"}
                borderRadius="32px"
                textAlign="center"
              >
               <a href="https://t.me/SKYNUR_TRAVEL">НАПИСАТЬ</a> 
              </Heading>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            width={{
              xl: "352px",
              lg: "352px",
              md: "643px",
              sm: "643px",
              base: "352px",
            }}
            height="192px"
            border="2px solid rgba(188, 188, 188, 0.2)"
            borderRadius="17px"
            mb={{md:"0", base:"20px"}}
          >
            <Box bg="transparent" mr={{md: "0", base:"20px"}}>
            <Svg_visa_phone />

            </Box>
            <Box>
              <Heading
                width="136px"
                fontSize="16px"
                color="#23262F"
                fontFamily="SF Pro Text"
                textAlign="start"
              >
                Подать заявку онлайн
              </Heading>
              <Heading
                color="#353945"
                fontSize="12px"
                fontWeight="400"
                fontFamily="Poppins, sans-serif"
                py="7px"
                width="105px"
                height="26px"
                mt="8px"
                bg={"rgba(146, 165, 239, 0.1)"}
                borderRadius="32px"
                textAlign="center"
              >
                  <a href="tel:+996709609030">ПОЗВОНИТЬ</a>
              </Heading>
            </Box>
          </Box>
        </Box>
       
        <Box
          bg="#F9F9F9"
          mt="25px"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          ml={{ xl: "0", lg: "0", md: "0", sm: "10px", base: "10px" }}
          mr={{ xl: "0", lg: "0", md: "0", sm: "10px", base: "10px" }}
        >
          <Box bg="#F9F9F9" display="flex" gap="12px" alignItems="center">
            <Box bg="transparent">
              <SvgEmail />
            </Box>
            <Heading
              bg="#F9F9F9"
              as="h4"
              color="#14213D"
              fontSize="14px"
              fontWeight="300"
              fontFamily="Jost, sans-serif"
            >
              <a href="mailto:skynur-travel@mail.ru">skynur-travel@mail.ru</a>
            </Heading>
          </Box>

          <Box bg="#F9F9F9" display="flex" gap="6px" alignItems="center">
            <Box bg="transparent">
              <SvgPhone />
            </Box>
            <Heading
              bg="#F9F9F9"
              as="h4"
              color="#14213D"
              fontSize="14px"
              fontWeight="300"
              fontFamily="Jost, sans-serif"
            >
              <a href="tel:+996709609030">0709-(609) - (030)</a>
            </Heading>
          </Box>

          <Box
            bg="#F9F9F9"
            display="flex"
            gap="6px"
            alignItems="center"
            mt={{ xl: "0", lg: "0", md: "0", sm: "44px", base: "44px" }}
          >
            <Box bg="#F9F9F9">
              <SvgLocation />
            </Box>
            <Heading
              bg="#F9F9F9"
              as="h4"
              color="#14213D"
              fontSize="14px"
              fontWeight="300"
              width={{
                xl: "378px",
                lg: "378px",
                md: "378px",
                sm: "192px",
                base: "173px",
              }}
              lineHeight={{
                xl: "0",
                lg: "0",
                md: "0",
                sm: "24px",
                base: "24px",
              }}
              fontFamily="Jost, sans-serif"
            >
              пр.Чуй 128\ ул.Логвиненко ул. Московская 226
            </Heading>
          </Box>

          <Box
            bg="#F9F9F9"
            display="flex"
            gap="6px"
            alignItems="center"
            mr={{ xl: "0", lg: "0", md: "0", sm: "17px", base: "17px" }}
            mt={{ xl: "0", lg: "0", md: "0", sm: "20px", base: "20px" }}
          >
            <Box bg="#F9F9F9">
              <SvgTime />
            </Box>
            <Heading
              bg="#F9F9F9"
              as="h4"
              color="#14213D"
              fontSize="12px"
              fontWeight="300"
              fontFamily="Jost, sans-serif"
            >
              09:00 - 19:00
            </Heading>
          </Box>
        </Box>
      </Box>
  </Box>
  );
};

