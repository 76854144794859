import {
  Box,
  Container,
  Heading,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

import { SvgWatsapp } from "../../assests/svg/SvgWatsapp";
import { SvgTelegram } from "../../assests/svg/SvgTelgram";
import { SvgTabData } from "../../assests/svg/SvgTabData";
import { SvgTabPrice } from "../../assests/svg/SvgTabPrice";
import { SvgTabTelegram } from "../../assests/svg/SvgTabTelegram";

import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/swiper.min.css";
import { useAppSelector } from "../../hooks/toursSelector";
import { SvgTabProgram } from "../../assests/svg/SvgTabProgram";
import { TabGalleryImage } from "./TabGalleryImage";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const TabDetail = () => {
  const { detailTours } = useAppSelector((s) => s.detailToursSlice);

  return (
    <Box
      position="relative"
      mt={{ xl: "200px", lg: "200px", md: "200px", sm: "495px", base: "495px" }}
      bg={"#F9F9F9"}
    >
      {detailTours ? (
        <Container maxW="1165px" bg={"#F9F9F9"}>
          <Heading
            color="#23262F"
            fontSize="32px"
            fontWeight={"600"}
            fontFamily="SF Pro Text"
            mb="24px"
            bg={"#F9F9F9"}
          >
            Информация о туре
          </Heading>

          <Tabs
            w="100%"
            bg="#FFFFFF"
            borderRadius="24px"
            backdropBlur="blur(10.8731px)"
            boxShadow=" 0px 40px 64px -32px rgba(15, 15, 15, 0.1)"
            border="1px solid #FCFCFD"
            rounded="24px"
          >
            <TabList
              borderRadius="24px 24px 0 0"
              display="flex"
              justifyContent="center"
              gap={{ xl: "36px", lg: "36px", md: "36px", sm: "0", base: "0" }}
              alignItems="center"
              pb={"30px"}
              pt="40px"
            >
              <Tab
                width="-30px"
                fontFamily="SF Pro Text"
                fontSize={{
                  xl: "14px",
                  lg: "14px",
                  md: "14px",
                  sm: "10px",
                  base: "10px",
                }}
                fontWeight="700"
                color="#23262F"
                lineHeight="16px"
                pb={{
                  xl: "30px",
                  lg: "30px",
                  md: "30px",
                  sm: "10px",
                  base: "10px",
                }}
              >
                О туре
              </Tab>
              <Tab
                fontFamily="SF Pro Text"
                fontSize={{
                  xl: "14px",
                  lg: "14px",
                  md: "14px",
                  sm: "10px",
                  base: "10px",
                }}
                fontWeight="700"
                color="#23262F"
                lineHeight="16px"
                pb={{
                  xl: "30px",
                  lg: "30px",
                  md: "30px",
                  sm: "10px",
                  base: "10px",
                }}
              >
                Галерея
              </Tab>
              <Tab
                fontFamily="SF Pro Text"
                fontSize={{
                  xl: "14px",
                  lg: "14px",
                  md: "14px",
                  sm: "10px",
                  base: "10px",
                }}
                fontWeight="700"
                color="#23262F"
                lineHeight="16px"
                pb={{
                  xl: "30px",
                  lg: "30px",
                  md: "30px",
                  sm: "10px",
                  base: "10px",
                }}
              >
                Проживание
              </Tab>
              <Tab
                fontFamily="SF Pro Text"
                fontSize={{
                  xl: "14px",
                  lg: "14px",
                  md: "14px",
                  sm: "10px",
                  base: "10px",
                }}
                fontWeight="700"
                color="#23262F"
                lineHeight="16px"
                pb={{
                  xl: "30px",
                  lg: "30px",
                  md: "30px",
                  sm: "10px",
                  base: "10px",
                }}
                width="68px"
              >
                Программа
              </Tab>
            </TabList>

            <TabPanels rounded="24px">
              <TabPanel
                rounded="24px"
                mb="62px"
                display={{
                  xl: "flex",
                  lg: "flex",
                  md: "flex",
                  sm: "block",
                  base: "block",
                }}
                justifyContent="space-between"
              >
                <Box>
                  <Box
                    display="flex"
                    gap="16px"
                    ml={{
                      xl: "64px",
                      lg: "64px",
                      md: "64px",
                      sm: "0",
                      base: "0",
                    }}
                  >
                    <Heading
                      color="#2F2A23"
                      fontSize="16px"
                      fontFamily="SF Pro Text regular"
                      fontWeight="600"
                      lineHeight="40px"
                      textAlign="center"
                      w="95px"
                      h="40px"
                      bg="rgba(85, 81, 248, 0.15)"
                      borderRadius="90px"
                    >
                      ${detailTours.price}
                    </Heading>
                    <Heading
                      color="#2F2A23"
                      fontSize="16px"
                      fontFamily="SF Pro Text regular"
                      fontWeight="600"
                      lineHeight="40px"
                      textAlign="center"
                      w="95px"
                      h="40px"
                      bg="rgba(85, 81, 248, 0.15)"
                      borderRadius="90px"
                    >
                      {detailTours.night} ночей
                    </Heading>
                  </Box>

                  <Box
                    ml={{
                      xl: "64px",
                      lg: "64px",
                      md: "64px",
                      sm: "20px",
                      base: "20px",
                    }}
                    mt="36px"
                    display={{
                      xl: "flex",
                      md: "flex",
                      lg: "flex",
                      sm: "block",
                      base: "block",
                    }}
                    gap={{
                      xl: "114px",
                      lg: "114px",
                      md: "114px",
                      sm: "0",
                      base: "0",
                    }}
                  >
                    <Box>
                      <Box
                        display="flex"
                        gap="18px"
                        alignItems="start"
                        mt={{
                          xl: "20px",
                          lg: "20px",
                          md: "20px",
                          sm: "42px",
                          base: "42px",
                        }}
                      >
                        <SvgTabData />
                        <Box display="flex" flexDirection="column" gap="2px">
                          <Heading
                            color="#777E90"
                            fontSize="16px"
                            fontFamily="SF Pro Text regular"
                            fontWeight="400"
                            lineHeight="24px"
                          >
                            Ближайшие даты
                          </Heading>
                          <Heading
                            color="#23262F"
                            fontSize="24px"
                            fontFamily={"SF Pro Text regular"}
                            fontWeight="600"
                            lineHeight="32px"
                          >
                            <select
                              name=""
                              id=""
                              style={{
                                outline: "none",
                                border: "none",
                                fontFamily: "SF Pro Text",
                              }}
                            >
                              {detailTours.date?.map((el: any, idx: any) => (
                                <option key={idx} value="">
                                  {el.from_date} - {el.to_date}
                                </option>
                              ))}
                            </select>
                          </Heading>
                          <Box bg="#B2BACE" w="248px" h="0.5px"></Box>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        gap="18px"
                        alignItems="start"
                        mt={{
                          xl: "20px",
                          lg: "20px",
                          md: "20px",
                          sm: "42px",
                          base: "42px",
                        }}
                      >
                        <SvgTabData />
                        <Box display="flex" flexDirection="column" gap="2px">
                          <Heading
                            color="#777E90"
                            fontSize="16px"
                            fontFamily="SF Pro Text regular"
                            fontWeight="400"
                            lineHeight="24px"
                          >
                            Длительность
                          </Heading>
                          <Heading
                            color="#23262F"
                            fontSize="24px"
                            fontFamily={"SF Pro Text regular"}
                            fontWeight="600"
                            lineHeight="32px"
                          >
                            {detailTours.night} Ночей
                          </Heading>
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        display="flex"
                        ml={{
                          xl: "0",
                          lg: "0",
                          md: "0",
                          sm: "7px",
                          base: "7px",
                        }}
                        gap="18px"
                        alignItems="start"
                        mt={{
                          xl: "20px",
                          lg: "20px",
                          md: "20px",
                          sm: "42px",
                          base: "42px",
                        }}
                      >
                        <SvgTabPrice />
                        <Box display="flex" flexDirection="column" gap="2px">
                          <Heading
                            color="#777E90"
                            fontSize="16px"
                            fontFamily="SF Pro Text regular"
                            fontWeight="400"
                            lineHeight="24px"
                          >
                            Стоимость
                          </Heading>
                          <Heading
                            color="#23262F"
                            fontSize="24px"
                            fontFamily={"SF Pro Text regular"}
                            fontWeight="600"
                            lineHeight="32px"
                          >
                            От ${detailTours.price}
                          </Heading>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        gap="18px"
                        alignItems="start"
                        mt={{
                          xl: "20px",
                          lg: "20px",
                          md: "20px",
                          sm: "42px",
                          base: "42px",
                        }}
                      >
                        <SvgTabTelegram />
                        <Box display="flex" flexDirection="column" gap="2px">
                          <Heading
                            color="#777E90"
                            fontSize="16px"
                            fontFamily="SF Pro Text regular"
                            fontWeight="400"
                            lineHeight="24px"
                          >
                            Вылет из
                          </Heading>
                          <Heading
                            color="#23262F"
                            fontSize="24px"
                            fontFamily={"SF Pro Text regular"}
                            fontWeight="600"
                            lineHeight="32px"
                          >
                            {detailTours.place_from}
                          </Heading>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  mr={{
                    xl: "40px",
                    lg: "40px",
                    md: "40px",
                    sm: "0",
                    base: "0",
                  }}
                  mt="18px"
                  ml={{ xl: "0", lg: "0", md: "0", sm: "34%", base: "37%" }}
                >
                  <Box
                    display="flex"
                    mt="140px"
                    mb="10px"
                    alignItems="center"
                    gap="25px"
                  >
                    <Heading
                      as="h4"
                      color="#777E90"
                      fontSize="14px"
                      fontFamily="Poppins,sans-serif"
                      fontWeight="400 "
                    >
                      <a href="https://t.me/SKYNUR_TRAVEL">
                        Написать в телеграм
                      </a>
                    </Heading>{" "}
                    <SvgTelegram />
                  </Box>
                  <Box display="flex" alignItems="center" gap="10px">
                    <Heading
                      as="h4"
                      color="#777E90"
                      fontSize="14px"
                      fontFamily="Poppins,sans-serif"
                      fontWeight="400 "
                    >
                      <a href="https://wa.me/996709609030">
                        Написать в Whatsapp
                      </a>
                    </Heading>{" "}
                    <SvgWatsapp />
                  </Box>
                </Box>
              </TabPanel>

              <TabPanel rounded="24px">
                <Box
                  ml={{
                    xl: "28px",
                    lg: "40px",
                    md: "40px",
                    sm: "0",
                    base: "0",
                  }}
                  bg="transparent"
                  rounded="24px"
                >
                  <Box>
                    <Box display="flex" flexDirection="column" gap={"16px"}>
                      <Heading
                        w="34px"
                        height="24px"
                        textAlign="center"
                        pt="2px"
                        bg={"#92A5EF"}
                        borderRadius="100px"
                        fontFamily="Poppins,sans-serif"
                        fontSize="14px"
                        color="#FCFCFD"
                        fontWeight="500"
                      >
                        01
                      </Heading>
                      <Heading
                        color="#2F2A23"
                        fontFamily="Poppins,sans-serif"
                        fontSize="24px"
                        fontWeight="600"
                        mt="24px"
                        mb="16px"
                      >
                        {detailTours.title}
                      </Heading>
                      <Heading
                        width={{
                          xl: "620px",
                          lg: "620px",
                          md: "620px",
                          sm: "315px",
                          base: "315px",
                        }}
                        fontFamily="SF Pro Text regular"
                        fontSize="14px"
                        lineHeight="24px"
                        fontWeight="400"
                        color="#777E90"
                      >
                        {detailTours.description}
                      </Heading>
                    </Box>
                  </Box>

                  <Box
                    display={{
                      xl: "flex",
                      lg: "flex",
                      md: "none",
                      sm: "none",
                      base: "none",
                    }}
                    flexWrap="wrap"
                    gap="22px"
                    w="100%"
                    mt="33px"
                    mb="73px"
                  >
                    {detailTours.gallery?.map((photo: any, index: any) => (
                      <TabGalleryImage detailTours={photo} index={index} />
                    ))}
                  </Box>

                  <Box
                    display={{
                      xl: "none",
                      lg: "none",
                      md: "block",
                      sm: "block",
                      base: "block",
                    }}
                    mt="30px"
                  >
                    <Swiper
                      pagination={true}
                      modules={[Pagination]}
                      className="mySwiper"
                      color="#F08523"
                    >
                      {detailTours.gallery?.map((el: any, idx: any) => (
                        <SwiperSlide key={idx}>
                          <Image
                            my="33px"
                            src={el.image}
                            alt="slide"
                            borderRadius="20px"
                            width="335px"
                            height="199px"
                            objectFit="cover"
                            cursor="pointer"
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Box>
                </Box>
              </TabPanel>

              <TabPanel rounded="24px">
                <Box
                  ml={{
                    xl: "40px",
                    lg: "40px",
                    md: "40px",
                    sm: "10px",
                    base: "10px",
                  }}
                >
                  <Heading
                    w="34px"
                    height="24px"
                    textAlign="center"
                    pt="2px"
                    bg={"#92A5EF"}
                    borderRadius="100px"
                    fontFamily="Poppins,sans-serif"
                    fontSize="14px"
                    color="#FCFCFD"
                    fontWeight="500"
                  >
                    02
                  </Heading>
                  <Heading
                    color="#2F2A23"
                    fontFamily="Poppins,sans-serif"
                    fontSize="24px"
                    fontWeight="600"
                    mt="24px"
                    mb="16px"
                  >
                    Услуги к туру
                  </Heading>

                  <Box
                    display={{
                      xl: "flex",
                      lg: "flex",
                      md: "block",
                      sm: "block",
                      base: "block",
                    }}
                    justifyContent="space-between"
                  >
                    <Box>
                      <Heading
                        color="#2F2A23"
                        fontFamily="SF Pro Text "
                        fontSize="20px"
                        fontWeight="500"
                        mb="10px"
                        mt="32px"
                      >
                        В стоимость включено
                      </Heading>
                      <Box mb="78px">
                        <Box>
                          <Heading
                            bg="transparent"
                            w={{
                              xl: "481px",
                              lg: "481px",
                              md: "473px",
                              sm: "300px",
                              base: "300px",
                            }}
                            color="#777E90"
                            size="14px"
                            fontWeight="400"
                            lineHeight="27px"
                            fontFamily="SF Pro Text regular"
                            display="flex"
                            flexDirection="column"
                          >
                            {detailTours.services?.map(
                              (services: any, idx: any) => (
                                <Heading
                                  color="#777E90"
                                  size="14px"
                                  fontWeight="400"
                                  lineHeight="27px"
                                  fontFamily="SF Pro Text regular"
                                  display="flex"
                                  flexDirection="column"
                                >
                                  • {services.services}
                                </Heading>
                              )
                            )}
                          </Heading>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      mr={{
                        xl: "40px",
                        lg: "40px",
                        md: "40px",
                        sm: "20px",
                        base: "20px",
                      }}
                    >
                      <Heading
                        color="#2F2A23"
                        fontFamily="SF Pro Text"
                        fontSize="20px"
                        fontWeight="500"
                        mb="10px"
                        mt="32px"
                      >
                        Дополнительные затраты
                      </Heading>
                      <Box mb="78px">
                        <Box>
                          <Heading
                            bg="transparent"
                            w={{
                              xl: "480px",
                              lg: "480px",
                              md: "473px",
                              sm: "307px",
                              base: "307px",
                            }}
                            color="#777E90"
                            size="14px"
                            fontWeight="400"
                            fontFamily="SF Pro Text regular"
                            lineHeight="27px"
                            display="flex"
                            flexDirection="column"
                          >
                            {detailTours.expenses?.map(
                              (services: any, idx: any) => (
                                <Heading
                                  color="#777E90"
                                  size="14px"
                                  fontWeight="400"
                                  fontFamily="SF Pro Text regular"
                                  lineHeight="27px"
                                >
                                  • {services.expenses}
                                </Heading>
                              )
                            )}
                          </Heading>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </TabPanel>

              <TabPanel rounded="24px">
                <Box
                  ml={{
                    xl: "40px",
                    lg: "40px",
                    md: "40px",
                    sm: "0",
                    base: "0",
                  }}
                >
                  <Heading
                    w="34px"
                    height="24px"
                    textAlign="center"
                    pt="2px"
                    bg={"#92A5EF"}
                    borderRadius="100px"
                    fontFamily="Poppins,sans-serif"
                    fontSize="14px"
                    color="#FCFCFD"
                    fontWeight="500"
                  >
                    03
                  </Heading>
                  <Heading
                    color="#2F2A23"
                    fontFamily="Poppins,sans-serif"
                    fontSize="24px"
                    fontWeight="600"
                    mt="24px"
                    mb="16px"
                  >
                    Программа тура
                  </Heading>

                  <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="space-between"
                  >
                    {detailTours.program?.map((el: any, idx: any) => (
                      <Box
                        key={idx}
                        justifyContent="space-between"
                        flexWrap={"wrap"}
                        mt="30px"
                        mb="45px"
                        bg="transparent"
                      >
                        <Box display="flex" bg="transparent">
                          <SvgTabProgram />
                          <Heading
                            fontFamily="SF Pro Text regular"
                            fontWeight="500"
                            fontSize="20px"
                            mb="10px"
                            color="#2F2A23"
                          >
                            {idx + 1} день
                          </Heading>
                        </Box>

                        <Box
                          w={{
                            xl: "375px",
                            lg: "375px",
                            md: "375px",
                            sm: "300px",
                            base: "300px",
                          }}
                        >
                            <li
                              style={{
                                fontFamily: "SF Pro Text regular",
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "#777E90",
                                lineHeight: "24px",
                                marginLeft : '10px'
                              }}
                            >
                              {el.programs}
                            </li>
                 
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      ) : (
        <Box maxW="800px" mx="auto">
          <SkeletonTheme
            baseColor="rgb(239, 239, 239)"
            highlightColor="#fcfcfd"
            width="100%"
            height="400px"
          >
            <p>
              <Skeleton />
            </p>
          </SkeletonTheme>
        </Box>
      )}
    </Box>
  );
};
