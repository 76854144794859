import {combineReducers, configureStore} from '@reduxjs/toolkit'
import  ToursSlice  from './Reducers/ToursSlice';
import detailToursSlice from './Reducers/ToursDetail';
import Countries from './Reducers/Countries';
import FAQ from './Reducers/FAQslice';
import visaSlice from './Reducers/Visa'
import visaDetailSlice from './Reducers/VisaDetail'


const rootReducer = combineReducers({
      ToursSlice,
      detailToursSlice,
      Countries,
      FAQ,
      visaSlice,
      visaDetailSlice,
})


export const setupStore = () =>{
    return configureStore({
        reducer :rootReducer
    })
}



export type RootState = ReturnType<typeof rootReducer>
type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
