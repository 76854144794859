import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { IToursBooking } from "../../types/toursBooking";




export const postTours = createAsyncThunk(
    'mySlice/postData',
    async (values: IToursBooking) => {
      const response = await axios.post<IToursBooking>(
        'http://api.papatruck.org/book-tour/',
        values
      );
      return response.data;
    }
  );