import { AppDispatch } from "../store"
import { countryFetching, countryFetchingError, countryFetchingSuccess } from "../Reducers/Countries"
import { $api } from "../../componets/api"

export const fetchingCountries = () => {
    return async(dispatch : AppDispatch)=>{
        try{
            dispatch(countryFetching())

            const response = await $api.get(`countries/`) 
            dispatch(countryFetchingSuccess(response.data))
        }catch(error: any){
            dispatch(countryFetchingError(error.message))
        }
    }
}