export const SvgAboutUsBlock_2 = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.4" width="100" height="100" rx="50" fill="#2AABEE" />
      <path
        d="M70 31.5056L62.4864 70.7307C62.4864 70.7307 61.4352 73.4503 58.5472 72.146L41.2114 58.3814L41.1311 58.3409C43.4727 56.1635 61.631 39.2567 62.4246 38.4903C63.6532 37.3034 62.8905 36.5968 61.464 37.4934L34.642 55.1324L24.2941 51.5269C24.2941 51.5269 22.6656 50.9271 22.509 49.6228C22.3503 48.3163 24.3477 47.6097 24.3477 47.6097L66.5328 30.4724C66.5328 30.4724 70 28.8948 70 31.5056Z"
        fill="#0094FF"
      />
    </svg>
  );
};
