import { Box, Image } from "@chakra-ui/react";

interface ITabGalleryImage {
  detailTours: JSX.Element | any,
  index : number | any
}

export const TabGalleryImage = ({ detailTours}: ITabGalleryImage) => {
  return (
    <Box >
        <Image src={detailTours.image} alt="" w={'335px'} h={'242px'} borderRadius='16px' />
    </Box>

  );
};
