import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { CSSReset, ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { setupStore } from "./store/store";

// extend the theme
const myTheme = extendTheme({
  colors: null,
  background: "#FFFFFF",
  button: null,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const store = setupStore();
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ChakraProvider theme={myTheme}>
        <CSSReset />
          <App />
      </ChakraProvider>
    </Provider>
  </BrowserRouter>
);
