export const SvgTabPrice = () => {
  return (
    <svg
      width="24"
      height="31"
      viewBox="0 0 24 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.67188 21.3298C8.67188 22.6198 9.66188 23.6598 10.8919 23.6598H13.4019C14.4719 23.6598 15.3419 22.7498 15.3419 21.6298C15.3419 20.4098 14.8119 19.9798 14.0219 19.6998L9.99187 18.2998C9.20187 18.0198 8.67188 17.5898 8.67188 16.3698C8.67188 15.2498 9.54187 14.3398 10.6119 14.3398H13.1219C14.3519 14.3398 15.3419 15.3798 15.3419 16.6698"
        stroke="#B1B5C3"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 13V25"
        stroke="#B1B5C3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 29H9C4 29 2 27 2 22V16C2 11 4 9 9 9H15C20 9 22 11 22 16V22C22 27 20 29 15 29Z"
        stroke="#B1B5C3"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
