import { useForm, SubmitHandler } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Box,
  Text,
  Heading,
} from "@chakra-ui/react";
import { useAppDispatch } from "../../hooks/toursDispatch";
import { useState } from "react";
import { SvgBookingPerson } from "../../assests/svg/SvgBookingPerson";
import { SvgNavbarPhone } from "../../assests/svg/SvgNavbarPhone";
import SuccessBooking from "../success/succesBooking";
import { postTours } from "../../store/Reducers/bookTours";

type FormData = {
  username: string;
  phone: string;
};

const FormBooking = () => {
  
  const [success, setSuccess] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = (data) => {

    dispatch(postTours(data))
    console.log(data);
    

    setTimeout(() => {
      setSuccess(true);
    }, 600);
    setTimeout(() => {
      setSuccess(false);
    }, 5000);
    reset();
  };

  return (
    <Box
      bg={success ? "#5551F8" : "#FFFFFF"}
      display={{
        xl: "flex",
        lg: "flex",
        md: "flex",
        sm: "block",
        base: "block",
      }}

      zIndex='55'
      p="38px 26px 42px 60px"

      justifyContent="space-between"
      rounded="24px"
      alignItems="center"
      gap="42px"
    >
      {!success ? (
        <Box
          display={{
            xl: "flex",
            lg: "flex",
            md: "flex",
            sm: "block",
            base: "block",
          }}
          gap="120px"
          bg="#FFFFFF"
        >
          <Box>
            <Heading
              width="240px"
              fontFamily="SF Pro Text"
              fontSize={{
                xl: "32px",
                lg: "32px",
                md: "32px",
                sm: "24px",
                base: "24px",
              }}
              fontWeight="600"
              color="#23262F"
              bg={"#FFFFFF"}
            >
              Бронь тура
            </Heading>
            <Text
              fontFamily="Poppins,sans-serif"
              fontSize={{
                xl: "19px",
                lg: "20px",
                md: "20px",
                sm: "16px",
                base: "16px",
              }}
              fontWeight="400"
              color="#777E90"
              bg={"#FFFFFF"}
              pt="10px"
            >
              Оставьте заявку на тур
            </Text>
          </Box>

          <form
            className="formBooking"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              background: "tranparent",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box
              mt={{ xl: "0", lg: "0", md: "0", sm: "38px", base: "38px" }}
              bg={"transparent"}
              display={{
                xl: "flex",
                lg: "flex",
                md: "flex",
                sm: "block",
                base: "block",
              }}
              gap="10px"
              borderRadius="24px"
            >
              <FormControl
                id="username"
                isInvalid={errors.username ? true : undefined}
                bg={"#FFFFFF"}
                h={{ xl: "43px", lg: "43px", md: "43px", sm: "127px" }}
              >
                <FormLabel
                  bg={"#FFFFFF"}
                  fontFamily="SF Pro Text regular"
                  fontSize="12px"
                  color="#2F2A23"
                  fontWeight="400"
                  ml="45px"
                >
                  Имя
                </FormLabel>
                <Box display="flex" alignItems="center" gap="16px">
                  <SvgBookingPerson />
                  <Box>
                    <Input
                      width="156px"
                      outline="none"
                      border="none"
                      variant="flushed"
                      type="text"
                      opacity={"0.5"}
                      {...register("username", { required: true })}
                      placeholder="Напишите ваше имя"
                    />
                    {errors.username && (
                      <FormErrorMessage color="red" w="200px">
                        {" "}
                        "Имя" обязательно
                      </FormErrorMessage>
                    )}
                  </Box>
                </Box>
                <Box
                  width={{
                    xl: "156px",
                    lg: "156px",
                    md: "156px",
                    sm: "160px",
                    base: "160px",
                  }}
                  marginLeft="46px"
                  height="1px"
                  _focus={{ bg: "#5551F8" }}
                  bg="#E4E4E4"
                  mb={{ xl: "0", lg: "0", md: "0", sm: "59px", base: "59px" }}
                ></Box>
              </FormControl>

              <FormControl
                m={{
                  xl: "0 40px",
                  lg: "0 40px ",
                  md: "0 40px ",
                  sm: "0",
                  base: "0",
                }}
                id="number"
                isInvalid={errors.phone ? true : undefined}
                bg="#FFFFFF"
                display="flex"
                flexDirection="column"
                borderRadius="24px"
              >
                <FormLabel
                  bg="#FFFFFF"
                  color="#2F2A23"
                  fontSize="12px"
                  fontFamily="SF Pro Text regular"
                  ml="45px"
                >
                  Номер
                </FormLabel>
                <Box display="flex" alignItems="center" gap="16px">
                  <SvgNavbarPhone />
                  <Box>
                    <Input
                      bg="transparent"
                      type="text"
                      width="156px"
                      opacity={"0.5"}
                      outline="none"
                      border="none"
                      variant="flushed"
                      placeholder="(---) 00-00-00"
                      {...register("phone", {
                        required: true,
                        pattern: /^\d+$/,
                      })}
                    />
                    {errors.phone?.type === "required" && (
                      <FormErrorMessage color="red">
                        {" "}
                        "Номер" обязательно
                      </FormErrorMessage>
                    )}
                    {errors.phone?.type === "pattern" && (
                      <FormErrorMessage color="red" w="200px">
                        Только цифры
                      </FormErrorMessage>
                    )}
                  </Box>
                </Box>
                <Box
                  width={{
                    xl: "140px",
                    lg: "140px",
                    md: "140px",
                    sm: "140px",
                    base: "140px",
                  }}
                  height="1px"
                  _focus={{ bg: "#5551F8" }}
                  bg="#E4E4E4"
                  ml="70px"
                ></Box>
              </FormControl>
            </Box>

                <Input 
                type="submit"
                w={{xl:"100%", lg: "100%", md: "100%", sm: "85%", base: "85%"}}
                     p="8px 43px 16px 40px"
                     border="2px solid #5551F8"
                     borderRadius="7px"
                     mt={{
                       xl: "18px",
                       lg: "18px",
                       md: "18px",
                       sm: "61px",
                       base: "61px",
                     }}
                     color="#2F2A23"
                     fontFamily="SF Pro Text regular"
                     fontWeight="600"
                     textAlign="center"
               
                />

          </form>
        </Box>
      ) : (
        <SuccessBooking />
      )}
    </Box>
  );
};

export default FormBooking;
