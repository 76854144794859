import { Box, Container } from "@chakra-ui/react";
import { Visa_hero } from "../../componets/hero_visa/Hero_visa";

export const VisaDetail = () => {
  return (
    <Box bg="#F9F9F9">
      <Visa_hero />
    </Box>
  );
};
