import { Box, Container, Heading, Text } from "@chakra-ui/react";
import { SvgAboutUs } from "../../assests/svg/SvgAboutUs";
import { SvgAboutUsBlock_1 } from "../../assests/svg/SvgAboutUs_block";
import { SvgAboutUsBlock_2 } from "../../assests/svg/SvgAboutIUs_block_2";
import { SvgEmail } from "../../assests/svg/SvgEmail";
import { SvgPhone } from "../../assests/svg/SvgPhone";
import { SvgLocation } from "../../assests/svg/SvgLocation";
import { SvgTime } from "../../assests/svg/SvgTime";
import { SvgForest } from "../../assests/svg/SvgForest";

export const AboutUs = () => {
  return (
    <Container id="aboutUs" maxW="1195px" mt="200px">
      <Box textAlign="center" mx="auto">
        <Heading
          as="p"
          fontSize="48px"
          fontWeight="700"
          fontFamily="SF Pro Text"
          color="#2F2A23"
          mb="20px"
        >
          О нас
        </Heading>
        <Box
          mx="auto"
          ml={{ xl: "42%", lg: "42%", md: "42%", sm: "26%", base: "26%" }}
          textAlign="center"
        >
          <SvgAboutUs />
        </Box>
        <Text
          as="p"
          textAlign="center"
          width={{
            xl: "415px",
            lg: "415px",
            md: "415px",
            sm: "357px",
            base: "357px",
          }}
          color="#777E90"
          fontSize="14px"
          fontFamily="SF Pro Text Regular"
          mt="12px"
          mx="auto"
        >
          Мы специализируемся на предоставлении клиентам различных услуг,
          связанных с поездками.
        </Text>
      </Box>
      <Box
        mt="64px"
        display={{
          xl: "flex",
          lg: "flex",
          md: "flex",
          sm: "block",
          base: "block",
        }}
        textAlign="center"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          width={{
            xl: "352px",
            lg: "352px",
            md: "643px",
            sm: "643px",
            base: "352px",
          }}
          height="192px"
          border="2px solid rgba(188, 188, 188, 0.2)"
          borderRadius="17px"
        >
          <Box bg="transparent">
            <SvgAboutUsBlock_1 />
          </Box>
          <Box>
            <Heading
              width="136px"
              fontSize="16px"
              color="#23262F"
              fontFamily="SF Pro Text Regular"
              fontWeight="600"
              textAlign="start"
              lineHeight="24px"
            >
              С нами комфортно
            </Heading>
            <Heading
              color="#353945"
              fontSize="12px"
              fontWeight="400"
              fontFamily="SF Pro Text Regular"
              py="7px"
              width="105px"
              height="26px"
              mt="8px"
              bg={"#EEEEFF"}
              borderRadius="32px"
              textAlign="center"
            >
              +50 МЕСТ
            </Heading>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          width={{
            xl: "352px",
            lg: "352px",
            md: "280px",
            sm: "352px",
            base: "352px",
          }}
          height="192px"
          border="2px solid rgba(188, 188, 188, 0.2)"
          borderRadius="17px"
          mt={{ xl: "0", lg: "0", md: "0", sm: "25px", base: "25px" }}
        >
          <Box bg="transparent">
            <SvgAboutUsBlock_2 />
          </Box>
          <Box>
            <Heading
              width="136px"
              fontSize="16px"
              color="#23262F"
              textAlign="start"
              fontWeight="600"
              fontFamily="SF Pro Text Regular"
            >
              Охрана и безопасность
            </Heading>
            <Heading
              color="#353945"
              fontSize="12px"
              fontWeight="400"
              py="7px"
              fontFamily="SF Pro Text Regular"
              width="105px"
              height="26px"
              mt="8px"
              bg={"#EEEEFF"}
              borderRadius="32px"
              textAlign="center"
            >
              ОПЫТ
            </Heading>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          width={{
            xl: "352px",
            lg: "352px",
            md: "280px",
            sm: "352px",
            base: "352px",
          }}
          height="192px"
          border="2px solid rgba(188, 188, 188, 0.2)"
          borderRadius="17px"
          mt={{ xl: "0", lg: "0", md: "0", sm: "25px", base: "25px" }}
        >
          <Box bg="transparent">
            <SvgForest/>
          </Box>
          <Box>
            <Heading
              width="155px"
              fontSize="16px"
              color="#23262F"
              fontFamily="SF Pro Text Regular"
              textAlign="start"
              fontWeight="600"
            >
              Рентабельность Персонализация
            </Heading>
            <Heading
              color="#353945"
              fontSize="12px"
              fontWeight="400"
              py="7px"
              width="105px"
              fontFamily="SF Pro Text Regular"
              height="26px"
              mt="8px"
              bg={"#EEEEFF"}
              borderRadius="32px"
              textAlign="center"
            >
              УДОБСТВО
            </Heading>
          </Box>
        </Box>
      </Box>
      <Box
        width="100%"
        height="2px"
        bg="rgba(188, 188, 188, 0.2)"
        mt="20px"
      ></Box>
      <Box
        mt="25px"
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <Box display="flex" gap="12px" alignItems="center">
          <Box bg="transparent">
            <SvgEmail />
          </Box>

          <Heading as="h4" color="#14213D" fontSize="14px" fontWeight="400" fontFamily='SF Pro Text Regular'>
            <a href="mailto:skynur-travel@mail.ru">skynur-travel@mail.ru</a>
          

          </Heading>
        </Box>

        <Box display="flex" gap="6px" alignItems="center">
          <Box bg="transparent">
            <SvgPhone />
          </Box>

          <Heading as="h4" color="#14213D" fontSize="14px" fontWeight="300" fontFamily='SF Pro Text Regular'>
          <a href="https://wa.me/996709609030">0709-(609) -(030)</a>

          </Heading>
        </Box>

        <Box
          display="flex"
          gap="6px"
          alignItems="center"
          mt={{ xl: "0", lg: "0", md: "0", sm: "44px", base: "44px" }}
        >
          <Box bg="transparent">
            <SvgLocation />
          </Box>
          <Heading
            as="h4"
            color="#14213D"
            fontSize="14px"
            fontWeight="300"
            width={{
              xl: "378px",
              lg: "378px",
              md: "378px",
              sm: "192px",
              base: "173px",
            }}
            lineHeight={{ xl: "0", lg: "0", md: "0", sm: "24px", base: "24px" }}
            fontFamily="SF Pro Text Regular"
          >
            пр.Чуй 128\ ул.Логвиненко | ул. Московская 226
          </Heading>
        </Box>

        <Box
          display="flex"
          gap="6px"
          alignItems="center"
          mr={{ xl: "0", lg: "0", md: "0", sm: "17px", base: "17px" }}
          mt={{ xl: "0", lg: "0", md: "0", sm: "20px", base: "20px" }}
        >
          <Box bg="transparent">
            <SvgTime />
          </Box>
          <Heading
            as="h4"
            color="#14213D"
            fontSize="12px"
            fontWeight="300"
            fontFamily="SF Pro Text Regular"
          >
            09:00 - 19:00
          </Heading>
        </Box>
      </Box>
    </Container>
  );
};
