export const SvgWatsapp = () => {
  return (
    <svg
    style={{background: "transparent"}}
      width="30"
      height="30"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="34" height="34" rx="17" fill="#48C95F" />
      <path
        d="M23.7378 10.253C22.0585 8.57959 19.8195 7.6499 17.4561 7.6499C12.5427 7.6499 8.56219 11.6166 8.56219 16.5129C8.56219 18.0624 8.99756 19.6119 9.7439 20.9134L8.5 25.4999L13.2268 24.2603C14.5329 24.9421 15.9634 25.314 17.4561 25.314C22.3695 25.314 26.35 21.3473 26.35 16.4509C26.2878 14.1577 25.4171 11.9265 23.7378 10.253ZM21.7476 19.6739C21.561 20.1697 20.6902 20.6655 20.2549 20.7275C19.8817 20.7895 19.3841 20.7895 18.8866 20.6655C18.5756 20.5416 18.1402 20.4176 17.6427 20.1697C15.4037 19.24 13.9732 17.0088 13.8488 16.8228C13.7244 16.6989 12.9159 15.6452 12.9159 14.5296C12.9159 13.414 13.4756 12.9181 13.6622 12.6702C13.8488 12.4223 14.0976 12.4223 14.2841 12.4223C14.4085 12.4223 14.5951 12.4223 14.7195 12.4223C14.8439 12.4223 15.0305 12.3603 15.2171 12.7942C15.4037 13.228 15.839 14.3437 15.9012 14.4056C15.9634 14.5296 15.9634 14.6535 15.9012 14.7775C15.839 14.9015 15.7768 15.0254 15.6524 15.1494C15.528 15.2733 15.4037 15.4593 15.3415 15.5213C15.2171 15.6452 15.0927 15.7692 15.2171 15.9551C15.3415 16.203 15.7768 16.8848 16.461 17.5046C17.3317 18.2483 18.0159 18.4963 18.2646 18.6202C18.5134 18.7442 18.6378 18.6822 18.7622 18.5582C18.8866 18.4343 19.322 17.9384 19.4463 17.6905C19.5707 17.4426 19.7573 17.5046 19.9439 17.5666C20.1305 17.6285 21.25 18.1864 21.4366 18.3103C21.6854 18.4343 21.8098 18.4963 21.8719 18.5582C21.9341 18.7442 21.9341 19.178 21.7476 19.6739Z"
        fill="white"
      />
    </svg>
  );
};
