import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface IFQAProps {
  el: string | any;
  idx: number;
}
export const FQABlock = ({ el, idx }: IFQAProps) => {
  const [active, setActive ] = useState(false)
  return (
    <Box
   
      borderLeft=" 3px solid #FC9011"
      borderRadius="10px"
      bg="transparent"
    >
     {
      el.description ? 
      <AccordionItem borderRadius="10px" border="none" bg="#FFFFFF">
      {({ isExpanded }) => (
        <>
          <h2 style={{ borderRadius: "10px" }}>
            <AccordionButton borderRadius="10px">
              <Box
                as="span"
                style={{ color: isExpanded && !active ? "#5551F8" : "#000000" }}
                flex="1"
                textAlign="left"
                fontSize="16px"
                borderRadius="20px"
                fontFamily="SF Pro Display,sans-serif"
                py={"12px"}
                ml="30px"
              >
                {el.title}
              </Box>
              {isExpanded && !active ? (
                <IoMdClose

                onClick={()=>  setActive(true)}

                  fontSize="26px"
                  color="#5551F8"
                  style={{ marginRight: "30px" }}
                />
              ) : (
                <AiOutlinePlus
                onClick={()=>  setActive(false)}
                  fontSize="26px"
                  color="#000000"
                  style={{ marginRight: "30px" }}
                />
              )}
            </AccordionButton>
          </h2>
          <AccordionPanel display={active ? 'none' : "block"} transition='1s' pb={4} color="black" borderRadius="20%">
            <Box borderRadius="10px" lineHeight="24px" margin="0 30px">
              {el.description}
            </Box>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
    :
    <SkeletonTheme
    baseColor="rgb(239, 239, 239)"
    highlightColor="#fcfcfd"
    width="100%"
    height="68px"
  >
    <p>
      <Skeleton />
    </p>
  </SkeletonTheme>
     }
    </Box>
  );
};
