export const SvgNavbarTelegram = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="35.3906"
        height="35.3906"
        rx="17.6953"
        fill="#5551F8"
        fill-opacity="0.28"
      />
      <path
        d="M19.4902 8.84766H13.2052C10.4752 8.84766 8.84766 10.4752 8.84766 13.2052V19.4827C8.84766 22.2202 10.4752 23.8477 13.2052 23.8477H19.4827C22.2127 23.8477 23.8402 22.2202 23.8402 19.4902V13.2052C23.8477 10.4752 22.2202 8.84766 19.4902 8.84766ZM16.3477 19.2577C14.7427 19.2577 13.4377 17.9527 13.4377 16.3477C13.4377 14.7427 14.7427 13.4377 16.3477 13.4377C17.9527 13.4377 19.2577 14.7427 19.2577 16.3477C19.2577 17.9527 17.9527 19.2577 16.3477 19.2577ZM20.7877 12.5077C20.7502 12.5977 20.6977 12.6802 20.6302 12.7552C20.5552 12.8227 20.4727 12.8752 20.3827 12.9127C20.2927 12.9502 20.1952 12.9727 20.0977 12.9727C19.8952 12.9727 19.7077 12.8977 19.5652 12.7552C19.4977 12.6802 19.4452 12.5977 19.4077 12.5077C19.3702 12.4177 19.3477 12.3202 19.3477 12.2227C19.3477 12.1252 19.3702 12.0277 19.4077 11.9377C19.4452 11.8402 19.4977 11.7652 19.5652 11.6902C19.7377 11.5177 20.0002 11.4352 20.2402 11.4877C20.2927 11.4952 20.3377 11.5102 20.3827 11.5327C20.4277 11.5477 20.4727 11.5702 20.5177 11.6002C20.5552 11.6227 20.5927 11.6602 20.6302 11.6902C20.6977 11.7652 20.7502 11.8402 20.7877 11.9377C20.8252 12.0277 20.8477 12.1252 20.8477 12.2227C20.8477 12.3202 20.8252 12.4177 20.7877 12.5077Z"
        fill="#2F2A23"
      />
    </svg>
  );
};
