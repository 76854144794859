import { Box, Heading, Image } from "@chakra-ui/react";
import errorImage from "../../assests/img/error.png";

export const Error = () => {
  return (
    <Box justifyContent='center' display='flex' alignItems='center' mt='18%'>
     <Box display='flex' alignItems='center'>
     <Box display='flex' flexDirection='column' >
        <Heading
          fontFamily="Zen Loop, cursive"
          fontSize="234px"
          color="#2F2A23"
          fontWeight="400"
          lineHeight="263px"
        >
          501
        </Heading>
        <Heading
          color='#2F2A23'
          fontSize='16px'
          fontWeight='400'
          fontFamily='SF Pro Text regular'
          py='43px 12px'  
        >Ошибка сервера</Heading>
        <Heading 
        color='#2F2A23'
        fontSize='16px'
        fontWeight='400'
        fontFamily='SF Pro Text  regular'

        >Повторите попытку позже</Heading>
      </Box>
      <Box><Image src={errorImage} alt="Error" /></Box>
     </Box>
    </Box>
  );
};
