import { Accordion, Box, Container } from "@chakra-ui/react";
import { FQABlock } from "../maps/FAQ";
import { useAppDispatch } from "../../hooks/toursDispatch";
import { useAppSelector } from "../../hooks/toursSelector";
import { useEffect } from "react";
import { fetchingFAQfn } from "../../store/actions/FAQAction";

export const FAQ = () => {
  const dispatch = useAppDispatch();
  const { FAQ } = useAppSelector((s) => s.FAQ);

  useEffect(() => {
    dispatch(fetchingFAQfn());
  }, []);
  return (
    <Container maxW="1335px">
      <Box
        mt="200px"
        w={{md:"100%", base:"108%"}}
        bg="rgba(188, 188, 188, 0.2)"
        pt={{ xl: "64px", lg: "64px", md: "64px", sm: "17px", base: "17px" }}
        pb="64px"
        borderRadius="30px"
      >
        <Container bg="transparent" maxW="790px">
          <Accordion
            mx="auto"
            display="flex "
            flexDirection="column"
            gap="18px"
            bg="transparent"
            borderRadius="10px"
          >
            {FAQ.map((el: any, idx: any) => (
              <FQABlock  key={idx} el={el} idx={idx} />
            ))}
          </Accordion>
        </Container>
      </Box>
    </Container>
  );
};
