import axios from "axios";
import {toursDetailFetching, toursDetailFetchingError, toursDetailFetchingSuccess } from "../Reducers/ToursDetail";
import { AppDispatch } from "../store";
import { $api } from "../../componets/api";

export const fetchingDetailTours = (id: number | any) =>{
 return async(dispatch:AppDispatch) => {
    try{
        dispatch(toursDetailFetching())
        const response = await $api.get(`countries/${id}/tour/${id}/`)        
        dispatch(toursDetailFetchingSuccess(response.data))
    }catch(error: any){
        dispatch(toursDetailFetchingError(error.message))
    }
 }
}