import { Box, Heading } from "@chakra-ui/react";
import { SvgPostSucces } from "../../assests/svg/ok";

const Success = () => {
  return (
    <Box 
    position='absolute'

    p={{xl:"177px 130px 215px 35px", lg:"177px 130px 215px 35px", md:"177px 130px 215px 35px", sm:"48px", base:"48px"}}
    bg={{xl:"rgba(255, 255, 255, 0.7)",lg:"rgba(255, 255, 255, 0.7)",md:"rgba(255, 255, 255, 0.7)",sm:"rgb(218 225 235 / 70%)",base:"rgb(218 225 235 / 70%)"}}
    backdropFilter="blur(10px)"
    borderRadius="14px"
    top={{ xl: "26px", lg: "26px", md: "26px", sm: "0", base: "0" }}
    left={{ xl: "46px", lg: "46px", md: "46px", sm: "0", base: "0" }}
    >
 
        <Heading
          bg="transparent"
          fontFamily="SF Pro Text regular"
          color="#2F2A23"
          fontSize="24px"
          fontWeight="500"
          letterSpacing='-0.06px'
          lineHeight='28px'
          w={{xl:"267px", lg : "267px", md:"267px", sm:"220px", base: "220px"}}
        >
          Ваша заявка успешно отправлена!
        </Heading>
        <Heading
          bg="transparent"
          color="#2F2A23"
          fontFamily="SF Pro Text regular"
          fontSize="14px"
          fontWeight="400"
          mt="20px"
          display='flex'
          gap='5px'
          alignItems='center'
          lineHeight='25px'
          letterSpacing='-0.05px'
        >
          Скоро мы с вами свяжемся. <SvgPostSucces/>
        </Heading>
    </Box>
  );
};

export default Success;
