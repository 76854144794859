import {
  Box,
  Container,
  Heading,
  Image,
  List,
  ListItem,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { useAppSelector } from "../../hooks/toursSelector";
import { useDebounce } from "../../hooks/useDebounce";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const Search = () => {

  const [active1, setActive1] = useState(false);
  const [image1, setImage1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [image2, setImage2] = useState(false);
  const [value, setValue] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [dropDown, setDropDown] = useState(false);
  const { tours } = useAppSelector((s) => s.ToursSlice);
  const navigate = useNavigate();

  let MainTours: any = [];
  MainTours.push(tours?.filter((el) => el.is_featured === true));

  console.log(MainTours);
  
  
  const debounced = useDebounce(value);
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const SearchBar = async () => {
    const res = await axios.get(
      `https://api.papatruck.org/tours/?search=${value}`
    );
    setSearchList(res.data);
  };
  useEffect(() => {
    if (value.length > 1) {
      SearchBar();
    }
    setDropDown(debounced.length > 1);
  }, [debounced]);

  const handleImage1 = () => {
    setActive1(true);
    setImage1(true);
  };

  const handleImage1Close = () => {
    setActive1(false);
    setImage1(false);
  };

  const handleImage2 = () => {
    setActive2(true);
    setImage2(true);
  };

  const handleImage2Close = () => {
    setActive2(false);
    setImage2(false);
  };  

  return (
    <Box pt="100px">      
      <Container
      id="hero"
      maxW="1300px"
      display="flex"
      justifyContent="space-between"
    >
      <Box>
        <Box>
          <Heading
            color="#2F2A23"
            fontSize={{ sm: "36px", md: "30px", lg: "48px", base: "36px" }}
            fontFamily="SF Pro Text"
            fontWeight="700"
            marginTop={{
              xl: "136px",
              lg: "136px",
              md: "136px",
              sm: "75px",
              base: "75px",
            }}
          >
            Эксклюзив
          </Heading>

          <Box
            fontFamily="Drunk"
            fontSize={{
              xl: "72px",
              lg: "72px",
              md: "72px",
              sm: "60px",
              base: "60px",
            }}
            fontWeight="700"
            lineHeight="70px"
            color="#FC9011"
          >
            на туры
          </Box>

          <Heading
            color="#2F2A23"
            fontSize={{ sm: "36px", md: "30px", lg: "48px", base: "36px" }}
            fontFamily="SF Pro Text"
            fontWeight="700"
          >
            по всему миру
          </Heading>

          <Box maxW="396px" position="relative">
            <Box
              mt={{
                xl: "65px",
                lg: "65px",
                md: "65px",
                sm: "38px",
                base: "38px",
              }}
            >
              <input
                onChange={onChange}
                type="text"
                placeholder="Поиск туров..."
                style={{
                  width: "100%",
                  height: "50px",
                  background: "#EFEFEF",
                  outline: "none",
                  border: "none",
                  color: "#000000",
                  padding: "0 15px",
                  borderRadius: "10px",
                }}
              />

              <Box
                position="absolute"
                bottom="0"
                right="0"
                bg="rgba(20, 33, 61, 0.6);"
                color="white"
                width="50px"
                height="50px"
                borderRadius="10px"
                zIndex="1"
                display="flex"
                justifyContent="center"
                alignItems="center"
                fontSize="24px"
              >
                <BiSearch style={{ background: "none" }} />
              </Box>
            </Box>

            {dropDown && (
                searchList.length ?
                <List
                position="absolute"
                left="0"
                right="0"
                top="63px"
                borderRadius="5px"
                shadow=" 0px 13px 32px 7px rgba(0, 0, 0, 0.1)"
                border="0.5px solid #E3E3E3"
                overflow="hidden"
                bg="#FFFFFF"
                height="150px"
                spacing={3}
              >
                {searchList.map((el: any, idx: any) => (
                  <ListItem
                  key={idx}
                    onClick={() => navigate(`/:title/detail-tours/${el.id}`)}
                    bg={"#FFFFFF"}
                    _hover={{ background: "#F2F2F2" }}
                    cursor="pointer"
                    transition="0.5s"
                    padding="9px 10px"
                  >
                    <Box
                      display="flex"
                      bg="transparent"
                      alignItems="center"
                      gap="10px"
                    >
                      <Heading
                        bg="transparent"
                        fontSize="14px"
                        color="#000000"
                        fontFamily="SF Pro Text regular"
                      >
                        {el.place_to.country}
                      </Heading>
                      <Box w="8.5px" h="1px" bg="#989898"></Box>
                      <Heading
                        bg="transparent"
                        fontSize="12px"
                        color=" #777E90"
                        lineHeight="20px"
                        fontWeight="400"
                        fontFamily="SF Pro Text regular"
                      >
                        {el.title}
                      </Heading>
                    </Box>
                  </ListItem>
                ))}
              </List>
              :
              <Box 
                bg="transparent"
              fontSize="14px"
              color="#000000"
              fontFamily="SF Pro Text regular">Нечего не найдено</Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box display={{ base: "none", md: "flex" }} gap="10px" mt="66px">
        <Box
          onClick={() =>
            navigate(`/:title/detail-tours/${MainTours[0][0]?.id}`)
          }
          position="relative"
          height="500px"
          rounded='20px'
          background='linear-gradient(180deg, rgba(0, 0, 0, 0) 55.5%, rgba(0, 0, 0, 0.6) 100%)'
          shadow='0 0 10px rgba(0, 0, 0, 0.5)'
        >
          <Box position='absolute' rounded='20px' top='0' left='0' right='0' bottom='0' background='linear-gradient(180deg, rgba(0, 0, 0, 0) 55.5%, rgba(0, 0, 0, 0.6) 100%)'  zIndex='2'/>
          {MainTours[0][0] ? (
            <Image
              src={MainTours[0][0]?.main_image}
              alt="slide"
              borderRadius="20px"
              height="500px"
              objectFit="cover"
              cursor="pointer"
              transition="1s"
              style={{ width: active1 || active2 ? "150px" : "500px" }}
            />
          ) : (
            <SkeletonTheme
              baseColor="rgb(239, 239, 239)"
              highlightColor="#fcfcfd"
              width="500px"
              height="500px"
            >
              <p>
                <Skeleton />
              </p>
            </SkeletonTheme>
          )}

          <Heading
            style={{ opacity: image1 || image2 ? "1" : "0" }}
            transition="1s"
            position="absolute"
            zIndex="33"
            bottom="22px"
            left="40px"
            fontSize="16px"
            fontFamily="SF Pro Text"
            fontWeight="700"
            background="transparent"
            color="#FFFFFF"
          >
            {MainTours[0][0]?.place_to.country}
          </Heading>
          <Box
            style={{ opacity: image1 || image2 ? "0" : "1" }}
            transition="1s"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >

            <Heading
            background="transparent"
            fontSize="24px"
            fontFamily="SF Pro Text"
            fontWeight="700"
            position="absolute"
            zIndex='33'
            left="23px"
            bottom="20px"
            color="#FFFFFF"
            >{MainTours[0][0]?.title?.slice(0,19)} </Heading>

            <Heading
              transition="1s"
              background="rgba(255, 255, 255, 0.2)"
              p="6px 10px"
              borderRadius="8px"
              position="absolute"
              right="23px"
              bottom="10px"
              zIndex='77'
              fontSize="28px"
              color="#FFFFFF"
              fontFamily="SF Pro Text"
              fontWeight="700"
            >
             от ${MainTours[0][0]?.price}
            </Heading>
          </Box>
        </Box>

        <Box
          onClick={() =>
            navigate(`/:title/detail-tours/${MainTours[0][1]?.id}`)
          }
          position="relative"
          rounded='20px'
          height="500px"
          onMouseOver={handleImage1}
          onMouseLeave={handleImage1Close}
        >
          <Box  
          position='absolute' rounded='20px' top='0' left='0' right='0' bottom='0' background='linear-gradient(180deg, rgba(0, 0, 0, 0) 55.5%, rgba(0, 0, 0, 0.6) 100%)'  zIndex='2'/>
          {MainTours[0][1] ? (
            <Image
              onMouseOver={handleImage1}
              onMouseLeave={handleImage1Close}
              src={MainTours[0][1]?.main_image}
              alt="slide"
              borderRadius="20px"
              height="500px"
              objectFit="cover"
              cursor="pointer"
              color="#FFFFFF"
              transition="1s"
              style={{ width: active1 ? "500px" : "150px" }}
            />
          ) : (
            <SkeletonTheme
              baseColor="rgb(239, 239, 239)"
              highlightColor="#fcfcfd"
              width="100px"
              height="500px"
            >
              <p>
                <Skeleton />
              </p>
            </SkeletonTheme>
          )}

          <Box
            style={{ opacity: image1 ? "1" : "0" }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >

            <Heading
            background="transparent"
            fontSize="24px"
            fontFamily="SF Pro Text"
            fontWeight="700"
            position="absolute"
            zIndex='33'
            left="23px"
            bottom="20px"
            color="#FFFFFF"
            >{MainTours[0][1]?.title?.slice(0,19)}...</Heading>

            <Heading
              background="rgba(255, 255, 255, 0.2)"
              p="6px 10px"
              borderRadius="8px"
              position="absolute"
              right="23px"
              zIndex='33'
              bottom="10px"
              fontSize="28px"
              fontFamily="SF Pro Text"
              fontWeight="700"
              color="#FFFFFF"
            >
            от ${MainTours[0][1]?.price}
            </Heading>
          </Box>

          <Heading
            style={{ opacity: image1 ? "0" : "1" }}
            position="absolute"
            zIndex="33"
            bottom="22px"
            left="40px"
            fontSize="16px"
            fontFamily="SF Pro Text"
            fontWeight="700"
            
            background="transparent"
            color="#FFFFFF"
          >
            {MainTours[0][1]?.place_to.country}
          </Heading>
        </Box>

        <Box
          onClick={() =>
            navigate(`/:title/detail-tours/${MainTours[0][2]?.id}`)
          }
          position="relative"
          rounded='20px'
          height="500px"
          onMouseOver={handleImage2}
          onMouseLeave={handleImage2Close}
        >
           <Box
         
           position='absolute' rounded='20px' top='0' left='0' right='0' bottom='0' background='linear-gradient(180deg, rgba(0, 0, 0, 0) 55.5%, rgba(0, 0, 0, 0.6) 100%)'  zIndex='2'/>
          {MainTours[0][2] ? (
            <Image
              onMouseOver={handleImage2}
              onMouseLeave={handleImage2Close}
              src={MainTours[0][2]?.main_image}
              alt="slide"
              borderRadius="20px"
              height="500px"
              objectFit="cover"
              cursor="pointer"
              transition="1s"
              style={{ width: active2 ? "500px" : "150px" }}
            />
          ) : (
            <SkeletonTheme
              baseColor="rgb(239, 239, 239)"
              highlightColor="#fcfcfd"
              width="100px"
              height="500px"
            >
              <p>
                <Skeleton />
              </p>
            </SkeletonTheme>
          )}

          <Box
            style={{ opacity: image2 ? "1" : "0" }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >

            <Heading
            background="transparent"
            fontSize="24px"
            fontFamily="SF Pro Text"
            fontWeight="700"
            position="absolute"
            zIndex='33'
            left="23px"
            bottom="20px"
            color="#FFFFFF"
            >{MainTours[0][2]?.title?.slice(0,19)} ... </Heading>
            <Heading
              background="rgba(255, 255, 255, 0.2)"
              p="6px 10px"
              borderRadius="8px"
              position="absolute"
              right="23px"
              bottom="10px"
              zIndex='33'
              fontSize="28px"
              fontFamily="SF Pro Text"
              fontWeight="700"
              color="#FFFFFF"
            >
              
            от ${MainTours[0][2]?.price}
            </Heading>
          </Box>
          <Heading
            style={{ opacity: image2 ? "0" : "1" }}
            position="absolute"
            zIndex="33"
            bottom="22px"
            left="40px"
            fontSize="16px"
            fontFamily="SF Pro Text"
            fontWeight="700"
            
            background="transparent"
            color="#FFFFFF"
          >
            {MainTours[0][2]?.place_to.country}
          </Heading>
        </Box>
         
         
      </Box>
    </Container>
          <Box display={{ base: "block", md: "none" }} mt={{md:"93px", base: dropDown ? '200px' : "93px"}} transition='1s' ml="10px" >
          {
        MainTours ?
        <Swiper spaceBetween={230} slidesPerView={2}>
          {MainTours.map((el: any) => (
            <Box>
              {el.map((el: any) => (
                <SwiperSlide>
                  <Box
                    onClick={() => navigate(`/:title/detail-tours/${el.id}`)}
                    position="relative"
                    height="276px"
                    rounded='33'
                    width="276px"
                  >
                     <Box position='absolute' rounded='20px' top='0' left='0' right='0' bottom='0' background='linear-gradient(180deg, rgba(0, 0, 0, 0) 55.5%, rgba(0, 0, 0, 0.6) 100%)'  zIndex='2'/>
                    <Image
                      src={el?.main_image}
                      alt="slide"
                      borderRadius="20px"
                      width="276px"
                      height="276"
                      objectFit="cover"
                      cursor="pointer"
                      transition="1s"
                    />

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Heading
                        background="transparent"
                        fontSize="15px"
                        fontFamily="SF Pro Text"
                        fontWeight="700"
                        position="absolute"
                        left="23px"
                        zIndex='33'
                        bottom="20px"
                        color="#FFFFFF"
                      >
                        {el.title}
                      </Heading>
                      <Heading
                        background="rgba(255, 255, 255, 0.2)"
                        p="5px"
                        borderRadius="8px"
                        position="absolute"
                        top="10px"
                        left="10px"
                        fontSize="17px"
                        zIndex='33'
                        fontFamily="SF Pro Text"
                        fontWeight="700"
                        color="#FFFFFF"
                      >
                       от ${el.price}
                      </Heading>
                    </Box>
                  </Box>
                </SwiperSlide>
              ))}
            </Box>
          ))}
        </Swiper>
              :
              <Box maxW="352px" mx="auto" mt='30px' rounded='24px'>
              <SkeletonTheme
                baseColor="rgb(239, 239, 239)"
                highlightColor="#fcfcfd"
                width="100%"
                height="290px"
              >
                <p>
                  <Skeleton />
                </p>
              </SkeletonTheme>
            </Box>
              }
      </Box>

    
    </Box>
  );
};
