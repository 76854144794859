import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface ICountries{
    countries : ICountries[],
    loader : boolean,
    error : null | string
}

const initialState: ICountries ={
    countries : [],
    loader : false,
    error : ''
}

export const CountriesSlice  = createSlice({
    name: 'countries',
    initialState,
    reducers:{
        countryFetching (state){
            state.loader = true
        },
        countryFetchingSuccess (state, action: PayloadAction<ICountries[]>){
            state.loader = false
            state.countries = action.payload
            state.error = ''
        },
        countryFetchingError (state, action:PayloadAction<string>){
            state.loader = false
            state.error = action.payload
            state.countries = []
        },
    }
})

export default CountriesSlice.reducer
export const {countryFetching,countryFetchingSuccess,countryFetchingError} = CountriesSlice.actions
