export const Svg_visa_phone = () => {
  return (
    <svg
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M103.683 69.3438C101.909 69.3438 100.506 67.9001 100.506 66.1676C100.506 64.6413 98.98 61.4651 96.4225 58.7013C93.9062 56.0201 91.1425 54.4526 88.8325 54.4526C87.0588 54.4526 85.6562 53.0088 85.6562 51.2763C85.6562 49.5438 87.1 48.1001 88.8325 48.1001C92.9575 48.1001 97.2887 50.3276 101.084 54.3288C104.631 58.0826 106.9 62.7438 106.9 66.1263C106.9 67.9001 105.456 69.3438 103.683 69.3438Z"
        fill="#4D86DE"
      />
      <path
        d="M118.574 69.3437C116.8 69.3437 115.397 67.9 115.397 66.1675C115.397 51.5237 103.476 39.6437 88.8735 39.6437C87.0998 39.6437 85.6973 38.2 85.6973 36.4675C85.6973 34.735 87.0998 33.25 88.8323 33.25C106.982 33.25 121.75 48.0175 121.75 66.1675C121.75 67.9 120.306 69.3437 118.574 69.3437Z"
        fill="#4D86DE"
      />
      <path
        opacity="0.4"
        d="M79.6337 83.6163L66.145 97.105C64.66 95.785 63.2162 94.4237 61.8137 93.0212C57.565 88.7312 53.7287 84.235 50.305 79.5325C46.9225 74.83 44.2 70.1275 42.22 65.4663C40.24 60.7638 39.25 56.2675 39.25 51.9775C39.25 49.1725 39.745 46.4913 40.735 44.0163C41.725 41.5 43.2925 39.19 45.4787 37.1275C48.1187 34.5288 51.0063 33.25 54.0588 33.25C55.2138 33.25 56.3688 33.4975 57.4 33.9925C58.4725 34.4875 59.4213 35.23 60.1638 36.3025L69.7338 49.7913C70.4763 50.8225 71.0125 51.7713 71.3837 52.6788C71.755 53.545 71.9613 54.4113 71.9613 55.195C71.9613 56.185 71.6725 57.175 71.095 58.1237C70.5588 59.0725 69.775 60.0625 68.785 61.0525L65.65 64.3112C65.1962 64.765 64.99 65.3013 64.99 65.9613C64.99 66.2913 65.0313 66.58 65.1138 66.91C65.2375 67.24 65.3613 67.4875 65.4438 67.735C66.1863 69.0962 67.465 70.87 69.28 73.015C71.1362 75.16 73.1162 77.3463 75.2612 79.5325C76.7462 80.9763 78.19 82.3788 79.6337 83.6163Z"
        fill="#4D86DE"
      />
      <path
        d="M121.626 100.611C121.626 101.766 121.42 102.963 121.008 104.118C120.884 104.448 120.76 104.778 120.595 105.108C119.894 106.593 118.986 107.995 117.79 109.315C115.769 111.543 113.541 113.151 111.025 114.183C110.984 114.183 110.943 114.224 110.901 114.224C108.468 115.214 105.828 115.75 102.981 115.75C98.7738 115.75 94.2776 114.76 89.5338 112.739C84.7901 110.718 80.0463 107.995 75.3438 104.571C73.7351 103.375 72.1263 102.179 70.6001 100.9L84.0888 87.4113C85.2438 88.2775 86.2751 88.9375 87.1413 89.3913C87.3476 89.4738 87.5951 89.5975 87.8838 89.7213C88.2138 89.845 88.5438 89.8863 88.9151 89.8863C89.6163 89.8863 90.1526 89.6388 90.6063 89.185L93.7413 86.0913C94.7726 85.06 95.7626 84.2763 96.7113 83.7813C97.6601 83.2038 98.6088 82.915 99.6401 82.915C100.424 82.915 101.249 83.08 102.156 83.4513C103.064 83.8225 104.013 84.3588 105.044 85.06L118.698 94.7538C119.77 95.4963 120.513 96.3625 120.966 97.3938C121.379 98.425 121.626 99.4563 121.626 100.611Z"
        fill="#4D86DE"
      />
    </svg>
  );
};
