export const SvgTelegramFooter = () => {
  return (
    <svg
    style={{background: 'transparent'}}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill="white" fill-opacity="0.29" />
      <path
        d="M21 9.45167L18.7459 21.2192C18.7459 21.2192 18.4306 22.0351 17.5642 21.6438L12.3634 17.5144L12.3393 17.5023C13.0418 16.849 18.4893 11.777 18.7274 11.5471C19.0959 11.191 18.8671 10.979 18.4392 11.248L10.3926 16.5397L7.28823 15.4581C7.28823 15.4581 6.79969 15.2781 6.75269 14.8868C6.70508 14.4949 7.30431 14.2829 7.30431 14.2829L19.9599 9.14171C19.9599 9.14171 21 8.66845 21 9.45167Z"
        fill="white"
      />
    </svg>
  );
};
