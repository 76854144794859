export const SvgBookingPerson = () => {
  return (
    <svg
      width="30"
      height="28"
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.857117"
        width="30"
        height="27.1429"
        rx="4.28571"
        fill="#FC9011"
        fill-opacity="0.26"
      />
      <path
        d="M15.0003 7.28571C13.1289 7.28571 11.6074 8.80713 11.6074 10.6786C11.6074 12.5143 13.0431 14 14.9146 14.0643C14.9717 14.0571 15.0289 14.0571 15.0717 14.0643C15.086 14.0643 15.0931 14.0643 15.1074 14.0643C15.1146 14.0643 15.1146 14.0643 15.1217 14.0643C16.9503 14 18.386 12.5143 18.3931 10.6786C18.3931 8.80713 16.8717 7.28571 15.0003 7.28571Z"
        fill="#2F2A23"
      />
      <path
        d="M18.6283 15.9643C16.6355 14.6358 13.3855 14.6358 11.3783 15.9643C10.4712 16.5715 9.97119 17.3929 9.97119 18.2715C9.97119 19.15 10.4712 19.9643 11.3712 20.5643C12.3712 21.2358 13.6855 21.5715 14.9998 21.5715C16.314 21.5715 17.6283 21.2358 18.6283 20.5643C19.5283 19.9572 20.0283 19.1429 20.0283 18.2572C20.0212 17.3786 19.5283 16.5643 18.6283 15.9643Z"
        fill="#2F2A23"
      />
    </svg>
  );
};
