export const SvgTime = () => {
  return (
    
<svg style={{background:"transparent"}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z" stroke="#5551F8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.7099 15.1798L12.6099 13.3298C12.0699 13.0098 11.6299 12.2398 11.6299 11.6098V7.50977" stroke="#5551F8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  );
};
