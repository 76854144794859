import axios from "axios"
import { AppDispatch } from "../store"
import { fetchingFAQError, fetchingFAQSuccess, fetchingFAQ } from "../Reducers/FAQslice"
import { $api } from "../../componets/api"

export const fetchingFAQfn = ()=>{
    return async(dispatch:AppDispatch) =>{
        try{
            dispatch(fetchingFAQ())

            const response = await $api.get(`FAQ/`) 
            dispatch(fetchingFAQSuccess(response.data))

        }catch(error: any){
            dispatch(fetchingFAQError(error.message))
        }
    }
}