import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { visaList } from "../../types/visa_list"

interface IVisaState {
    visa : visaList[],
    loader : boolean,
    error : string | null
}

const initialState:IVisaState = {
    visa: [],
    loader: false,
    error : ''
}

export const visaSlice = createSlice({
    name : "visa",
    initialState,
    reducers : {
        fetchingVisa (state){
            state.loader = true
        },
        fetchingVisaSuccess (state, action : PayloadAction<visaList[]>){
            state.loader = false
            state.visa = action.payload
            state.error = ''
        },
        fetchingVisaError (state, action : PayloadAction<string>){
            state.loader = false
            state.error = action.payload
        },
    }
})

export default visaSlice.reducer
export const  {fetchingVisa, fetchingVisaSuccess, fetchingVisaError} = visaSlice.actions