import { Box, Heading, Image } from "@chakra-ui/react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";

interface ICard {
  el: string | any;
  idx: number;
}

export const Card = ({ el, idx }: ICard) => {
  return (
    <Box>
      {el ? (
        <Link
          to={`/${el.place_to.country}/detail-tours/${el.id}`}
          onClick={() => window.scroll(0, 0)}
        >
          <Box key={idx} position="relative" margin="16px auto">
            <Image
              src={el.main_image}
              alt=""
              width={{
                xl: "352px",
                lg: "352px",
                md: "252px",
                sm: "356px",
                base: "356px",
              }}
              height="256px"
              borderRadius="24px"
            />
            <Heading
              as="h4"
              position="absolute"
              top="16px"
              left="16px"
              width="70px"
              height="26px"
              color="#FCFCFD"
              fontSize="12px"
              fontFamily="Poppins,sans-serif"
              fontWeight="500"
              pl="9px"
              py="7px"
              bg="rgba(20, 33, 61, 0.6)"
              boxShadow="0px 8px 16px -8px rgba(15, 15, 15, 0.1)"
              borderRadius="20px"
            >
              От ${el.price}
            </Heading>
            <Heading
              fontFamily="SF Pro Text"
              color="#23262F"
              fontWeight="600"
              fontSize="24px"
              as="h6"
              mt="14px"
              mb="5px"
            >
              {el.place_to.country}
            </Heading>
            <Heading
              as="p"
              width={{
                xl: "352px",
                lg: "352px",
                md: "247px",
                sm: "247px",
                base: "358px",
              }}
              color="#777E90"
              fontSize="18px"
              fontWeight="400"
              fontFamily="Poppins,sans-serif"
              lineHeight="20px"
            >
              {el.title}
            </Heading>
          </Box>
        </Link>
      ) : (
        <SkeletonTheme
        baseColor="rgb(239, 239, 239)"
        highlightColor="#fcfcfd"
        width="352px"
        height="256px"
      >
        <p>
          <Skeleton />
        </p>
      </SkeletonTheme>
      )}
    </Box>
  );
};
