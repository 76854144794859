export const SvgTabData = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23 10H9C8.44772 10 8 10.4477 8 11V23C8 23.5523 8.44772 24 9 24H23C23.5523 24 24 23.5523 24 23V11C24 10.4477 23.5523 10 23 10ZM9 8C7.34315 8 6 9.34315 6 11V23C6 24.6569 7.34315 26 9 26H23C24.6569 26 26 24.6569 26 23V11C26 9.34315 24.6569 8 23 8H9Z"
        fill="#B1B5C3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 16C13.4477 16 13 16.4477 13 17C13 17.5523 13.4477 18 14 18H21C21.5523 18 22 17.5523 22 17C22 16.4477 21.5523 16 21 16H14ZM11 20C10.4477 20 10 20.4477 10 21C10 21.5523 10.4477 22 11 22H17C17.5523 22 18 21.5523 18 21C18 20.4477 17.5523 20 17 20H11Z"
        fill="#B1B5C3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 6C10.4477 6 10 6.44771 10 7V11C10 11.5523 10.4477 12 11 12C11.5523 12 12 11.5523 12 11V7C12 6.44771 11.5523 6 11 6ZM21 6C20.4477 6 20 6.44771 20 7V11C20 11.5523 20.4477 12 21 12C21.5523 12 22 11.5523 22 11V7C22 6.44771 21.5523 6 21 6Z"
        fill="#B1B5C3"
      />
    </svg>
  );
};
