import { Box, Heading, Image } from "@chakra-ui/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/swiper.min.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/toursSelector";
import "react-loading-skeleton/dist/skeleton.css";



export const Slide = ({detailTours} : any) => {

  const { tours } = useAppSelector((s) => s.ToursSlice);
  
  const filter = tours.filter((el) => el.title !== detailTours.title) 


  const navigate = useNavigate();
  const mediaLink = (el: any) => {
    navigate(`/:title/detail-tours/${el.id}`);
    window.scroll(0, 0);
  };


  return (
    <Box>  
      <Box pt="200px" bg="#F9F9F9">
      <Heading
        ml={{ xl: "168px", lg: "168px", md: "168px", sm: "20px", base: "20px" }}
        bg="#F9F9F9"
        fontFamily="SF Pro Text"
        fontWeight="600"
        color="#23262F"
        lineHeight="32px"
        pb="34px"
      >
        Похожие туры
      </Heading>
      <Box
        ml={{ xl: "168px", lg: "168px", md: "168px", sm: "20px", base: "20px" }}
        display={{
          xl: "flex",
          lg: "block",
          md: "block",
          sm: "none",
          base: "none",
        }}
        bg="#F9F9F9"
      >

          <Swiper
          slidesPerView={3.5}
          spaceBetween={30}
          className="mySwiper"
          style={{ background: "#F9F9F9" }}
        >
   

          {filter?.map((el: any, idx: any) =>

            
              <SwiperSlide style={{ background: "#F9F9F9" }}>      
                  <NavLink
                  to={`/${el.place_to.country}/detail-tours/${el?.id}`}
                  onClick={() => window.scroll(0, 0)}
                >     
                     <Box
                     key={idx}
                     w="352px"
                     h="290px"
                     bg="#FFFFFF"
                     boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)"
                     borderRadius="18px"
                     pt="10px"
                   >
                     <Image
                       src={el?.main_image}
                       w="336px"
                       h="170px"
                       ml={"8px"}
                       bg="F9F9F9"
                       objectFit="cover"
                       borderRadius="14px"
                       alt=""
                     />
                     <Box
                       mt="10px"
                       display="flex"
                       justifyContent="space-between"
                     >
                       <Heading
                         color="#23262F"
                         fontFamily="SF Pro Text regular"
                         fontWeight="600"
                         fontSize="24px"
                         lineHeight="32px"
                         ml="8px"
                       >
                         {el?.place_to.country}
                       </Heading>
                       <Heading
                         color="#FCFCFD"
                         fontFamily="Poppins,sans-serif"
                         fontWeight="500"
                         fontSize="12px"
                         mr="8px"
                         textAlign="center"
                         w="70px"
                         h="26px"
                         bg=" rgba(20, 33, 61, 0.6)"
                         boxShadow="0px 8px 16px -8px rgba(15, 15, 15, 0.1)"
                         borderRadius="6px"
                         pt="7px"
                       >
                        от ${el?.price}
                       </Heading>
                     </Box>
                     <Heading
                       mt="10px"
                       ml="8px"
                       color=" #777E90"
                       fontFamily="SF Pro Text regular"
                       fontWeight="400"
                       lineHeight="20px"
                       fontSize="18px"
                     >
                       {el?.title}
                     </Heading>
                   </Box> 
                </NavLink>       
              </SwiperSlide>
            
          )}
        </Swiper>  
    
      </Box>

      <Box
        display={{
          xl: "none",
          lg: "none",
          md: "none",
          sm: "flex",
          base: "flex",
        }}
        flexDirection="column"
        gap="24px"
        bg="transparent"
        alignItems="center"
        justifyContent="center"
      >
        {filter.map((el: any, idx: any) => (
          <Box
            onClick={() => mediaLink(el)}
            key={idx}
            w="352px"
            h="290px"
            bg="#FFFFFF"
            boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)"
            borderRadius="18px"
            pt="10px"
          >
            <Image
              src={el.main_image}
              w="336px"
              h="170px"
              ml={"8px"}
              bg="transparent"
              objectFit="cover"
              borderRadius="14px"
              alt=""
            />
            <Box mt="10px" display="flex" justifyContent="space-between">
              <Heading
                color="#23262F"
                fontFamily="SF Pro Text"
                fontWeight="600"
                fontSize="24px"
                lineHeight="32px"
                ml="8px"
              >
                {el.place_to.country}
              </Heading>
              <Heading
                color="#FCFCFD"
                fontFamily="Poppins,sans-serif"
                fontWeight="500"
                fontSize="12px"
                mr="8px"
                textAlign="center"
                w="70px"
                h="26px"
                bg=" rgba(20, 33, 61, 0.6)"
                boxShadow="0px 8px 16px -8px rgba(15, 15, 15, 0.1)"
                borderRadius="6px"
                pt="7px"
              >
                ${el.price}
              </Heading>
            </Box>
            <Heading
              mt="10px"
              ml="8px"
              color=" #777E90"
              fontFamily="SF Pro Text"
              fontWeight="400"
              lineHeight="20px"
              fontSize="18px"
            >
              {el.title}
            </Heading>
          </Box>
        ))}
      </Box>
    </Box>

    </Box>
 
  );
};
