import {Box, Button, Container, Heading, Link, Tooltip} from "@chakra-ui/react";
import { MdOutlineNavigateNext } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useAppDispatch } from "../../hooks/toursDispatch";
import { useAppSelector } from "../../hooks/toursSelector";
import { useEffect } from "react";
import { fetchingCountries } from "../../store/actions/Countries";
import { fetchingVisaFn } from "../../store/actions/visaAction";
import {filterToursCountry} from "../../store/Reducers/ToursSlice";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const Visa = () => {
  const dispatch = useAppDispatch()
  const {countries} = useAppSelector((s)=> s.Countries)
  const {visa} = useAppSelector((s)=> s.visaSlice)
  

  useEffect(()=>{
    dispatch(fetchingCountries())
    dispatch(fetchingVisaFn())
  },[])

  return (
    <Box
      maxW="1120px"
      mx="auto"
      mt={{ xl: "0", lg: "0", md: "0", sm: "610px", base: "610px" }}
    >
      <Box w='100%' h='1px' bg='rgba(85, 81, 248, 0.2)' m='0 auto' mt='146px' mb='35px' />
      <Container id="visa_block" maxW="823px">
        <Heading
          as="h4"
          fontSize="48px"
          fontFamily="SF Pro Text"
          fontWeight="700"
          color="#2F2A23"
        >
          Виза
        </Heading>
        <Box display={{ xl: "flex", lg: "flex", md: "flex", sm: "block" }}>
          <Box>
            <Box
              as="p"
              bg="#92A5EF"
              borderRadius="100px"
              width="42px"
              height="24px"
              mt="35px"
              color="#FCFCFD"
              fontSize="14px"
              fontWeight="500"
              textAlign="center"
              fontFamily="Poppins,sans-serif"
            >
              02
            </Box>
            <Box
              as="p"
              color="#2F2A23"
              fontSize="24px"
              fontFamily="SF Pro Text "
              fontWeight="500"
              mt="20px"
            >
              Путешествуйте вместе с нами
            </Box>
            <Heading
              as="p"
              width={{
                xl: "473px",
                lg: "473px",
                md: "473px",
                sm: "358px",
                base: "358px",
              }}
              color="#777E90"
              fontFamily="Poppins,sans-serif"
              fontSize="14px"
              fontWeight="400"
              mt="16px"
              mb="46px"
              lineHeight="24px"
            >
              Предлагаем список стран, где вы cможете активно пошрузиться в
              неописуемый отдых с нашей командой SKYNUR.
            </Heading>
            {visa.map((el : any, idx :any) => (
              <NavLink
              key={idx}
                to={`/detail-visa/${el.id}`}
                onClick={() => window.scroll(0, 0)}
              >
                <Box
                  cursor="pointer" 
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  px="20px"
                  width={{
                    xl: "285px",
                    lg: "285px",
                    md: "285px",
                    sm: "356px",
                    base: "356px",
                  }}
                  height="50px"
                  bg="rgba(252, 144, 17, 0.26)"
                  borderRadius="8px"
                  mt={"12px"}
                >
                  <Heading
                    color="#2F2A23"
                    fontWeight="600"
                    fontSize="16px"
                    fontFamily="Poppins,sans-serif"
                    bg="transparent"
                    width='558px'
                  >
                    {el.country.name}
                  </Heading>
                  <Box
                    bg="transparent"
                    display="flex "
                    alignItems="center"
                    justifyContent="space-around"
                    width="80px"
                  >
                    <Box
                      bg=" rgba(255, 255, 255, 0.5)"
                      width="0.8px"
                      height="30px"
                    ></Box>
                    <Heading
                      bg="transparent"
                      color="#2F2A23"
                      fontSize="12px"
                      fontWeight="400"
                    >
                      Виза
                    </Heading>
                  </Box>
                </Box>
              </NavLink>
            ))}
          </Box>

       {countries ? 
         <Box
         display='flex'
         flexDirection="column"
         justifyContent="space-between"
         mt="40px"
         ml={{ xl: "68px", lg: "68px", md: "68px", sm: "0", base: "0" }}
       >
         {countries.map((el:any, idx:any) => (
           <Tooltip
             key={idx}
             position="relative"
             label="Посмотреть туры"
             placement="right"
             color="rgba(20, 33, 61, 0.6)"
             fontFamily="Poppins,sans-serif"
             boxShadow="-7px 8px 23px -2px rgba(0, 0, 0, 0.1)"
             w={{xl:"238px",lg:"238px",md:"238px",sm:"352px",base:"352px"}}
             height="50px"
             bg="#FFFFFF"
             fontSize="14px"
             textAlign="center"
             pt="10px"
           >
            <Link href={'#tours'} _hover={{textDecoration: 'none'}}>
              <Button
                onClick={() => dispatch(filterToursCountry(el.country))}
                width={{xl:"285px",lg:"285px",md:"285px",sm:"357px",base:"357px"}}
                display="flex"
                justifyContent="flex-start"
                _hover={{ bg: "#5551F8", color: "#FFFFFF", transition: ".5s" }}
                bg="rgba(146, 165, 239, 0.1)"
                height="50px"
                borderRadius="8px"
                mb="4px"
                color="#2F2A23"
                textAlign="start"
              >
                {el.country}
                <MdOutlineNavigateNext
                  style={{
                    background: "transparent",
                    position: "absolute",
                    right: "20px",
                    color: "#90A6DF",
                    fontSize: "20px",
                  }}
                />
              </Button>
            </Link>
           </Tooltip>
         ))}
       </Box>
       :
       <SkeletonTheme
       baseColor="rgb(239, 239, 239)"
       highlightColor="#fcfcfd"
       width="285px"
       height="500px"
     >
       <p>
         <Skeleton />
       </p>
     </SkeletonTheme> 
      }      
        </Box>
      </Container>
      <Box w='100%' h='1px' bg='rgba(85, 81, 248, 0.2)' m='0 auto' mt='146px' mb='35px' />
    </Box>
  );
};
