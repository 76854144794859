import { Box, Button, Container, Heading} from "@chakra-ui/react";

import { useState } from "react";
import {IoIosClose} from 'react-icons/io'
import { SvgTelegram } from "../../../assests/svg/SvgTelgram";
import { SvgWatsapp } from "../../../assests/svg/SvgWatsapp";
import { SvgSkynurTravel } from "../../../assests/svg/SvgSkynurTravel";
import { SvgNavbarTelegram } from "../../../assests/svg/SvgNavbarTelegram";
import { SvgNavbarPhone } from "../../../assests/svg/SvgNavbarPhone";
import { NavLink, useNavigate } from "react-router-dom";
import { SvgLogo } from "../../../assests/svg/SvgLogo";

export const Header = () => {
  const [open, setOpen] = useState<any>(false);

  const navigate = useNavigate()

  const navbar = [
    {value : "Туры", href : "#tours"},
    {value : "О нас", href : "#aboutUs"},
    {value : "Виза", href : "#visa_block"},
    {value : "Авиабилеты", href : "#tickets"},
  ]

  const navigateFn = () => {

    navigate('/')
    setOpen(false)
  }

  return (
    <Box
      position="fixed"
      zIndex="99"
      width="100%"
      bg="#FFFFFF"
      boxShadow="0px 2px 17px rgba(0, 0, 0, 0.1)"
      height="74px"
    >
      <Container
        maxW="1300px"
        display="flex"
        alignItems="center"
        justifyContent='space-between'
        mb={{ xl: "0", lg: "0", md: "0", sm: "30px", base: "30px" }}
      >
        <NavLink to="/" onClick={() => window.scroll(0, 0)}>
          <Box width="92px" height="37px" mr="80px" cursor="pointer" mt={{xl:"0",lg:"0",md:"0",sm:"15px",base:"15px"}}>
            <SvgLogo/>
          </Box>
        </NavLink>
        <Box position="absolute" right="20px" zIndex={"99"}>
   
   </Box>
        <Box
        display={{ base: "none", md: "flex" }}
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box display="flex" gap="33px">
       
        {
          navbar.map((el,idx)=>(
            <Heading
            key={idx}
            onClick={() => navigate('/')}
              color="#2F2A23"
              size="14px"
              fontWeight="700"
              lineHeight="68px"
              transition='1s'
              fontFamily="Poppins , sans-serif"
              cursor="pointer"
              _hover={{ color: "#5551F8" }}
              _focus={{ color: "#5551F8" }}
              _active={{ color: "#5551F8" }}
            >
              <a className="active" href={el.href}>{el.value}</a>
            </Heading>
          ))
        }

        </Box>
        <Button
        display={{xl: 'block', lg: "block" , md: "block" , sm: "none", base: "none"}}
            type="button"
            p='0 10px'
            bg="#5551F8"
            color="#FFFFFF"
            fontFamily="SF Pro Text "
            fontSize="14px"
            cursor="pointer"
            borderRadius='7px' 
          >
            <a href="tel:+996709609030">+996(709) 60-90-30</a>
          </Button>
          
      </Box> 
  
      <Box
              onClick={() => setOpen(true)}
              display={{ base: "flex", md: "none" }}
              flexDirection="column"
              alignItems="end"
              cursor="pointer"
            >
              <Box
                width="20px"
                border="1px solid #000000"
                borderRadius="2px"
                mb="8px"
                mt="29px"
              ></Box>
              <Box
                width="37px"
                border="1px solid #000000"
                borderRadius="2px"
              ></Box>
            </Box>

      

        {/* Menu navbar */}
      <Box
          position="absolute"
          top={"0"}
          borderRadius={"  40px 0px 0px 40px "}
          zIndex="80"
          transition="1s"
          style={{ right: open ? "0rem" : "-950px" }}
          display={{
            xl: "none",
            lg: "none",
            md: "none",
            sm: "block",
            base: "block",
          }}
          justifyContent="space-between"
          alignItems="center"
          textAlign="center"
          height="200vh"
          width="90%"
          backdropFilter='blur(10px)'
          bg={"#FFFFFF"}
          flexDirection="column"
        >
               <Box
              onClick={() => setOpen(false)}
              position='absolute'
              right='15px'
              top='20px'
              color="black"
              zIndex={"99"}
              bg={"#FFFFFF"}
              background='transparent'
            >
             <IoIosClose style={{background:"transparent", fontSize: "50px"}}/>
            </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems={"start"}
            gap={"33px"}
            mt="63px"
            mr={"60%"}
            ml={"23px"}
            bg={"transparent"}
          >
            
          {navbar.map((el,idx)=>(
            <Heading
            key={idx}
            onClick={navigateFn}
            as="h3"
            color="#2F2A23"
            size="20px"
            fontWeight="700"
            fontFamily="SF Pro Text"
            bg={"transparent"}
            _active={{ color: "#5551F8" }}
            _hover={{ color: "#5551F8" }}
            _focus={{ color: "#5551F8" }}
          >
            <a  href={el.href}> {el.value}</a>
          </Heading>
          ))}

          

          </Box>
          <Box
            w="299px"
            height={"0.5px"}
            bg="rgba(85, 81, 248, 0.2)"
            ml={"27px"}
            mt={"82px"}
            mb={"35px"}
          ></Box>

          <Box
            display={"flex"}
            gap={"25px"}
            alignItems={"center"}
            ml={"23px"}
            bg={"transparent"}
          >
            <SvgTelegram />

            <Heading
              fontFamily={"Poppins, sans-serif"}
              color={"#777E90"}
              fontSize={"14px"}
              fontWeight={"400"}
              bg={"transparent"}
              _active={{ color: "#5551F8" }}
              _focus={{ color: "#5551F8" }}
            >
              <a href="https://t.me/SKYNUR_TRAVEL">Написать в телеграм</a>


            </Heading>
          </Box>
          <Box
            display={"flex"}
            gap={"25px"}
            alignItems={"center"}
            ml={"23px"}
            mt={"16px"}
            bg={"transparent"}
          >
            <SvgWatsapp />
            <Heading
              fontFamily={"Poppins, sans-serif"}
              color={"#777E90"}
              fontSize={"14px"}
              fontWeight={"400"}
              bg={"transparent"}
              _active={{ color: "#5551F8" }}
              _focus={{ color: "#5551F8" }}
            >
              <a href="https://wa.me/996709609030">Написать в Whatsapp</a>

            </Heading>
          </Box>

          <Box
            w="299px"
            height={"0.5px"}
            bg="rgba(85, 81, 248, 0.2)"
            ml={"27px"}
            mt={"30px"}
            mb={"65px"}
          ></Box>

          <Box bg={"transparent"} mb={"39px"} ml={"22px"}>
            <NavLink to={"/"} onClick={() => setOpen(false)}>
              <SvgSkynurTravel />
            </NavLink>
          </Box>

          <a href="https://www.instagram.com/skynur_travel/">
          <Box
            display={"flex"}
            gap={"25px"}
            alignItems={"center"}
            ml={"23px"}
            bg={"transparent"}
          >
            <SvgNavbarTelegram />

            <Heading
              fontFamily={"Poppins, sans-serif"}
              color={"#777E90"}
              fontSize={"14px"}
              fontWeight={"400"}
              bg={"transparent"}
              _active={{ color: "#5551F8" }}
              _focus={{ color: "#5551F8" }}
            >
              Instagram
            </Heading>
          </Box>
          </a>

          <a href="tel:+996709609030">
          <Box
            display={"flex"}
            gap={"25px"}
            alignItems={"center"}
            ml={"23px"}
            bg={"transparent"}
            mt={"16px"}
          >
            <SvgNavbarPhone />

            <Heading
              fontFamily={"Poppins, sans-serif"}
              color={"#777E90"}
              fontSize={"14px"}
              fontWeight={"400"}
              bg={"transparent"}
              _active={{ color: "#5551F8" }}
              _focus={{ color: "#5551F8" }}
            >
              Позвонить
            </Heading>
          </Box>
          </a>
        </Box>
      </Container>
      <Box 
      onClick={()=> setOpen(false)}
      display={open ? 'block' : "none"} 
      backdropFilter='blur(50px)' opacity='50%' position='absolute' top='0' left='0' bottom='0' right='0' h='200vh' bg='#000'></Box>

    </Box>
  );
};
