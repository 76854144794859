import { Box } from "@chakra-ui/react";
import { TourBooking } from "../../componets/tourBooking/tourBooking";
import { TabDetail } from "../../componets/tab/TabDetail";
import { Slide } from "../../componets/slide/Slide";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch } from "../../hooks/toursDispatch";
import { fetchingDetailTours } from "../../store/actions/ToursDetailAction";
import { useAppSelector } from "../../hooks/toursSelector";
import { Loader } from "../../componets/loader/Loader";

export const DetailTours = () => {
  const dispatch = useAppDispatch()
  const {loader, error, detailTours}  = useAppSelector(s => s.detailToursSlice)
  const params = useParams()
  useEffect(()=>{
    dispatch(fetchingDetailTours(params.toursId))
  },[params])
  
  if(loader) return <p><Loader/></p>
  return (
    <Box pt={"155px"} bg={"#F9F9F9"}>
      {error && <p>Something Went wrong...  Please try again later </p>}
      <TourBooking detailTours={detailTours}/>
      <TabDetail />
      <Slide detailTours={detailTours} />
    </Box>
  );
};
