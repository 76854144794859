import {
  Box,
  Button,
  Container,
  Heading,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { Card } from "../maps/Card";
import { BiChevronDown } from "react-icons/bi";
import { SvgFilter } from "../../assests/svg/SvgFilter";
import { Svg_filter_select } from "../../assests/svg/Svg_filter_select";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks/toursDispatch";
import { fetchingTours, filterTours } from "../../store/actions/ToursAction";
import { useAppSelector } from "../../hooks/toursSelector";
import { filterToursCountry } from "../../store/Reducers/ToursSlice";

export const Filter = () => {
  const [viewMore, setViewMore] = useState(9);
  const dispatch = useAppDispatch();
  const {  result } = useAppSelector((s) => s.ToursSlice);
  const [closePoppover, setClosePoppover] = useState(false)

  useEffect(() => {
    dispatch(fetchingTours());
  }, []);

  const toggleViewMore = () => {
    setViewMore(viewMore === 9 ? result.length : 9);
  };

  const filter = [
    "Горящие туры",
    'Экскурсионные туры',
    "Пляжные туры",
    "На 7 дней",
    "Цены: по убыванию",
    "Цены: по возрастанию",
  ];

  const { countries } = useAppSelector((s) => s.Countries);

  const filterFn = (el: string): void => {
    dispatch(filterTours(el));
    setClosePoppover(true)
    setTimeout(()=> {setClosePoppover(false)},1000)
  };
  const handleCountries =(el: string | any)=>{
    dispatch(filterToursCountry(el.country))
    setClosePoppover(true)
    setTimeout(()=> {setClosePoppover(false)},1000)    

  }
  return (
    <Container id="tours" maxW="1310px" mx="auto">
      <Box
        bg="#FFFFFF"
        boxShadow=" 0px 18px 25px -10px rgba(15, 15, 15, 0.1)"
        borderRadius="24px"
        height="83px"
        width="100%"
        mt="200px"
        border="0.5px solid #E8E8E8"
        zIndex={99}
        display="flex"
        alignItems="center"
        gap='10px'
      >
        <Popover>
          <PopoverTrigger>
            <Button
              background="white"
              color="#2F2A23"
              border="2px solid #E8E8E8"
              outline="none"
              width="145px"
              height="35px"
              borderRadius="42px"
              fontSize="12px"
              padding="0 35px"
              fontWeight="700"
              fontFamily="SF Pro Text"
              cursor="pointer"
              display="flex"
              ml='20px'
              gap="17px"
            >
              Страны <Svg_filter_select />
            </Button>
          </PopoverTrigger>
          <PopoverContent
          display={closePoppover ? 'none' : "block"}
            bg="#FFFFFF"
            p="0"
            boxShadow="0px 4px 16px 13px rgba(0, 0, 0, 0.25)"
            borderRadius="6px"
            w="207px"
            border="none"
          >
            {countries.map((el: any, idx: any) => (
              <Heading
                onClick={() => handleCountries(el)}
                key={idx}
                color="#000000"
                pt="11px"
                pl="18px"
                pb="11px"
                fontSize="12px"
                fontWeight="400"
                fontFamily="SF Pro Text regular"
                _hover={{ background: "#f8dbb9" }}
                _focus={{ background: "#f8dbb9" }}
                _active={{ background: "#f8dbb9" }}
                cursor="pointer"
              >
                {el.country}
              </Heading>
            ))}
          </PopoverContent>
        </Popover>

        <Box
          display={{
            xl: "flex",
            lg: "flex",
            md: "none",
            sm: "none",
            base: "none",
          }}
          gap="25px"
          justifyContent="space-around"
        >
          {filter.map((el, idx) => (
            <Button
            key={idx}
              onClick={() => filterFn(el)}
              variant="outline"
              border="2px solid #E8E8E8"
              borderRadius="42px"
              fontWeight="100"
              color="#000000"
              fontSize="12px"
              fontFamily="SF Pro Text"
              p="5px 24px"
              cursor="pointer"
              _active={{ bg: "#FC9011" }}
              _focus={{
                bg: "#FC9011",
                color: " #FFFFFF",
                fontWeight: "700",
                border: "none",
              }}
            >
              {el}
            </Button>
          ))}
        </Box>

        <Box
          display={{
            xl: "none",
            lg: "none",
            md: "block",
            sm: "block",
            base: "block",
          }}>
             <Popover>
          <PopoverTrigger>
            <Button
             width="80px"
             height="40px"
             background="#FC9011"
             borderRadius="42px"
          ml='66px'
             cursor="pointer"
            >
              <SvgFilter/>
            </Button>
          </PopoverTrigger>
          <PopoverContent
              display={closePoppover ? 'none' : "block"} 
            bg="#FFFFFF"
            p="0"
            boxShadow="0px 4px 16px 13px rgba(0, 0, 0, 0.25)"
            borderRadius="6px"
            w="207px"
            border="none"
          >
            {filter.map((el: any, idx: any) => (
              <Heading
              onClick={() => filterFn(el)}
                key={idx}
                color="#000000"
                pt="11px"
                pl="18px"
                pb="11px"
                fontSize="12px"
                fontWeight="400"
                fontFamily="SF Pro Text regular"
                _hover={{ background: "#FFA500"}}
                _active={{ background: "#FFA500"}}
                _focus={{ background: "#FFA500"}}
                cursor="pointer"
              >
                {el}
              </Heading>
            ))}
          </PopoverContent>
        </Popover>
     
        </Box>
      </Box>
      <Box>
        <Heading
          fontFamily="SF Pro Text"
          fontSize={{
            xl: "48px",
            lg: "48px",
            md: "48px",
            sm: "36px",
            base: "36px",
          }}
          fontWeight="700"
          lineHeight="60px"
          color="#2F2A23"
          as={"h4"}
          mt={"53px"}
        >
          Туры
        </Heading>
        <Text
          fontFamily={"Poppins, sans-serif"}
          fontWeight={"400"}
          fontSize={{
            xl: "24px",
            lg: "24px",
            md: "24px",
            sm: "20px",
            base: "20px",
          }}
          lineHeight={"32px"}
          letterSpacing={"-0.24px"}
          color={"#777E90"}
        >
          Путешествия в любую точку мира
        </Text>
      </Box>
      <Box display="flex" flexWrap="wrap" mt="44px" gap="30px 110px">
        {result.slice(0, viewMore).map((el, idx) => (
          <Card key={idx} el={el} idx={idx} />
        ))}
      </Box>
      {result.length > 9 && (
        <Button
          onClick={toggleViewMore}
          width="162px"
          height="48px"
          border="2px solid #E6E8EC"
          borderRadius="90px"
          color="#23262F"
          fontSize="16px"
          fontWeight="700"
          fontFamily="SF Pro Text"
          marginLeft={{
            xl: "41%",
            lg: "41%",
            md: "41%",
            sm: "17%",
            base: "26%",
          }}
          mt="56px"
        >
          {viewMore === 9 ? "Все туры" : "Скрыть"}
          <BiChevronDown
            style={{ marginLeft: "16px", color: "#777E90", fontWeight: "700" }}
          />
        </Button>
      )}
    </Container>
  );
};

