import { Box, Container, Heading } from "@chakra-ui/react";
import { SvgInsta } from "../../../assests/svg/SvgInsta";

import { SvgWatsapp } from "../../../assests/svg/SvgWatsapp";
import { SvgTelegramFooter } from "../../../assests/svg/SvgFooterTelegram";

import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

export const Footer = () => {
  const [open, setOpen] = useState<any>(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const toursLink = () => {
    navigate("/");
    window.scroll(0, 600);
    setOpen(false);
  };
  const aboutTickets = () => {
    navigate("/");
    window.scroll(0, 4820);
    setOpen(false);
  };
  const visaLink = () => {
    navigate("/");
    window.scroll(100, 8000);
    setOpen(false);
  };
  const aboutUsLink = () => {
    navigate("/");
    window.scroll(0, 6000);
    setOpen(false);
  };
  const backCall = () => {
    navigate('/')
    window.scroll(0, 0)
  }

  return (
    <Box
      bg={pathname === "/" ? "#FFFFFF" : "#F9F9F9"}
      p={{ md: "200px 0 17px 0", base: "200px 47px 0 15px" }}
    >
      <Container
        maxW="1300px"
        bg="#23262F"
        borderRadius="20px"
        width={{md:"100%", base:"108%"}}
        p={{ md: "0px 94px 23px 86px", base: "" }}
      >
        <Box
          position="relative"
          ml={{ xl: "0", lg: "0", md: "0", sm: "107px", base: "107px" }}
        >
          <Heading
            top={{
              xl: "81px",
              lg: "81px",
              md: "81px",
              sm: "81px",
              base: "50px",
            }}
            left={{ md: "0", base: "5%" }}
            style={{
              fontSize: "14px",
              color: "#F0F0F0",
              fontWeight: "700",
              fontFamily: "SF Pro Text",
              background: "transparent",
              position: "absolute",
            }}
          >
            Контакты
          </Heading>
          <Box
            position="absolute"
            top={{
              xl: "123px",
              lg: "123px",
              md: "123px",
              sm: "123px",
              base: "87px",
            }}
            bg="transparent"
          >
            <a href="https://www.instagram.com/skynur_travel/">
              <SvgInsta />
            </a>
          </Box>
          <Box
            position="absolute"
            top={{
              xl: "123px",
              lg: "123px",
              md: "123px",
              sm: "123px",
              base: "87px",
            }}
            left={{ xl: "48px", md: "50px", sm: "50px", base: "65px" }}
            bg="transparent"
          >
            <a href="https://t.me/SKYNUR_TRAVEL">
              <SvgTelegramFooter />
            </a>
          </Box>
        </Box>

        <Box
          display={{ lg: "none", base: "inline-block" }}
          w="80%"
          justifyContent="center"
          height="1px"
          position="absolute"
          bottom={{
            xl: "67px",
            lg: "67px",
            md: "67px",
            sm: "67px",
            base: "385px",
          }}
          bg="#F4F5F6"
        ></Box>

        <Box position="relative">
          <Heading
            position="absolute"
            top={{
              xl: "81px",
              lg: "81px",
              md: "81px",
              sm: "81px",
              base: "160px",
            }}
            left={{
              xl: "271px",
              lg: "200px",
              md: "190px",
              sm: "180px",
              base: "26px",
            }}
            size="14px"
            _hover={{ color: "#F0F0F0", transition: "0.5s" }}
            color="#777E90"
            fontWeight="700"
            fontFamily="SF Pro Text"
            background="transparent"
            cursor="pointer"
            fontSize="14px"
            onClick={() => window.scroll(0, 0)}
          >
            Главная
          </Heading>

          <Heading
              onClick={toursLink}
            position="absolute"
            top={{
              xl: "130px",
              lg: "81px",
              md: "81px",
              sm: "81px",
              base: "201px",
            }}
            left={{
              xl: "271px",
              lg: "200px",
              md: "190px",
              sm: "180px",
              base: "37px",
            }}
            size="14px"
            _hover={{ color: "#F0F0F0", transition: "0.5s" }}
            color="#777E90"
            fontWeight="700"
            fontFamily="SF Pro Text"
            background="transparent"
            cursor="pointer"
            fontSize="14px"
          >
            <a href="#tours">Туры</a>
          </Heading>
        </Box>
        <Box position="relative">
          <Heading
           onClick={visaLink}
            position="absolute"
            top={{
              xl: "81px",
              lg: "81px",
              md: "81px",
              sm: "128px",
              base: "160px",
            }}
            left={{
              xl: "450px",
              lg: "364px",
              md: "330px",
              sm: "340px",
              base: "191px",
            }}
            size="14px"
            _hover={{ color: "#F0F0F0", transition: "0.5s" }}
            color="#777E90"
            fontWeight="700"
            fontFamily="SF Pro Text"
            background="transparent"
            cursor="pointer"
            fontSize="14px"
          >   
            <a href="#visa_block">Виза</a>
          </Heading>
          <Heading
          onClick={aboutTickets}
            position="absolute"
            _hover={{ color: "#F0F0F0", transition: "0.5s" }}
            top={{
              xl: "130px",
              lg: "130px",
              md: "130px",
              sm: "80px",
              base: "200px",
            }}
            left={{
              xl: "450px",
              lg: "364px",
              md: "330px",
              sm: "340px",
              base: "172px",
            }}
            size="14px"
            color="#777E90"
            fontWeight="700"
            fontFamily="SF Pro Text"
            background="transparent"
            cursor="pointer"
            fontSize="14px"
          >
            <a href="#tickets">Авиабилеты</a>
          </Heading>
        </Box>
        <Box position="relative" textAlign="center">
          <Heading
              onClick={aboutUsLink}
            position="absolute"
            _hover={{ color: "#F0F0F0", transition: "0.5s" }}
            top={{
              xl: "81px",
              lg: "81px",
              md: "81px",
              sm: "81px",
              base: "242px",
            }}
            left={{
              xl: "650px",
              lg: "522px",
              md: "459px",
              sm: "515px",
              base: "36px",
            }}
            size="14px"
            color="#777E90"
            fontWeight="700"
            fontFamily="SF Pro Text"
            background="transparent"
            cursor="pointer"
            fontSize="14px"
          >
            <a href="#aboutUs">О нас</a>
          </Heading>
          <Heading
            onClick={backCall}
            position="absolute"
            top={{
              xl: "130px",
              lg: "130px",
              md: "130px",
              sm: "130px",
              base: "242px",
            }}
            left={{
              xl: "650px",
              lg: "522px",
              md: "459px",
              sm: "515px",
              base: "166px",
            }}
            _hover={{ color: "#F0F0F0", transition: "0.5s" }}
            size="14px"
            color="#777E90"
            fontWeight="700"
            fontFamily="SF Pro Text"
            background="transparent"
            cursor="pointer"
            fontSize="14px"
          >
            Обратная связь
          </Heading>
        </Box>
        <Box
          position="relative"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Heading
            position="absolute"
            top={{
              xl: "81px",
              lg: "81px",
              md: "81px",
              sm: "175px",
              base: "318px",
            }}
            left={{ xl: "897px", lg: "900px", md: "660px", sm: "0" }}
            size="12px"
            color="#FFFFFF"
            fontFamily="Poppins,sans-serif"
            background="transparent"
            cursor="pointer"
            fontWeight="400"
          >
            Связаться по whatsapp
          </Heading>
          <Box
            position="absolute"
            bg="transparent"
            width="255px"
            height="48px"
            border="2px solid #E6E8EC"
            borderRadius="90px"
            top={{
              xl: "110px",
              lg: "110px",
              md: "110px",
              sm: "203px",
              base: "356px",
            }}
            right={{ xl: "0", lg: "40px", md: "8px", sm: "205px" }}
          >
            <Heading
              color="#FFFFFF"
              position="absolute"
              top="9px"
              right="95px"
              bg="transparent"
              size="14px"
            >
              <a href="https://wa.me/996709609030">Написать</a>
            </Heading>
            <Box position={"absolute"} right="16px" top="5px" bg="transparent">
              <SvgWatsapp />
            </Box>
          </Box>
        </Box>
        <Box
          w="78%"
          height="1px"
          position="absolute"
          bottom={{
            xl: "90px",
            lg: "90px",
            md: "90px",
            sm: "67px",
            base: "103px",
          }}
          bg="#F4F5F6"
        ></Box>
        <Box
          textAlign="center"
          display={{
            xl: "flex",
            lg: "flex",
            md: "flex",
            sm: "block",
            base: "block",
          }}
          alignItems="center"
          justifyContent="space-between"
          bg="#23262F"
          pt={{
            xl: "227px",
            lg: "206px",
            md: "240px",
            sm: "455px",
            base: "455px",
          }}
        >
          <Heading
            bg="#23262F"
            fontSize="12px"
            color="#797979"
            pb={{ xl: "15", lg: "15", md: "0", sm: "19px", base: "19px" }}
          >
            © 2023 «SKYNUR». Все права защищены
          </Heading>
          <Heading pb="15px" bg="#23262F" fontSize="12px" color="#797979">
            <a href="https://wa.me/996559692626">Разработал Motion Web LLC</a>
          </Heading>{" "}
        </Box>
      </Container>
    </Box>
  );
};
