import { Box } from "@chakra-ui/react";
import { Search } from "../../componets/SearchBar/Search";
import { Filter } from "../../componets/filterTours/Filter";
import { Tickets } from "../../componets/tickets/Tickets";
import { Visa } from "../../componets/visa/Visa";
import { AboutUs } from "../../componets/aboutUs/aboutUs";
import { FAQ } from "../../componets/FAQ/FAQ";

export const MainPage: React.FC = () => {
  return (
        <Box bg="#FFFFFF">
          <Search />
          <Filter />
          <Tickets />
          <Visa />
          <AboutUs />
          <FAQ />
        </Box>
  );
};
