export const SvgNavbarPhone = () => {
  return (
    <svg
      width="30"
      height="28"
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.857117"
        width="30"
        height="27.1429"
        rx="4.28571"
        fill="#FC9011"
        fill-opacity="0.26"
      />
      <path
        d="M14.3212 16.5357L12.9998 17.8571C12.7212 18.1357 12.2784 18.1357 11.9926 17.8643C11.9141 17.7857 11.8355 17.7143 11.7569 17.6357C11.0212 16.8928 10.3569 16.1143 9.76408 15.3C9.17836 14.4857 8.70693 13.6714 8.36408 12.8643C8.02836 12.05 7.85693 11.2714 7.85693 10.5286C7.85693 10.0428 7.94265 9.57856 8.11408 9.14999C8.2855 8.71428 8.55693 8.31428 8.93551 7.95713C9.39265 7.50713 9.89265 7.28571 10.4212 7.28571C10.6212 7.28571 10.8212 7.32856 10.9998 7.41428C11.1855 7.49999 11.3498 7.62856 11.4784 7.81428L13.1355 10.15C13.2641 10.3286 13.3569 10.4928 13.4212 10.65C13.4855 10.8 13.5212 10.95 13.5212 11.0857C13.5212 11.2571 13.4712 11.4286 13.3712 11.5928C13.2784 11.7571 13.1426 11.9286 12.9712 12.1L12.4284 12.6643C12.3498 12.7428 12.3141 12.8357 12.3141 12.95C12.3141 13.0071 12.3212 13.0571 12.3355 13.1143C12.3569 13.1714 12.3784 13.2143 12.3926 13.2571C12.5212 13.4928 12.7426 13.8 13.0569 14.1714C13.3784 14.5428 13.7212 14.9214 14.0926 15.3C14.1641 15.3714 14.2426 15.4428 14.3141 15.5143C14.5998 15.7928 14.6069 16.25 14.3212 16.5357Z"
        fill="#2F2A23"
      />
      <path
        d="M22.1214 18.95C22.1214 19.15 22.0856 19.3571 22.0142 19.5571C21.9928 19.6143 21.9714 19.6714 21.9428 19.7285C21.8214 19.9857 21.6642 20.2285 21.4571 20.4571C21.1071 20.8428 20.7214 21.1214 20.2856 21.3C20.2785 21.3 20.2714 21.3071 20.2642 21.3071C19.8428 21.4785 19.3856 21.5714 18.8928 21.5714C18.1642 21.5714 17.3856 21.4 16.5642 21.05C15.7428 20.7 14.9214 20.2285 14.1071 19.6357C13.8285 19.4285 13.5499 19.2214 13.2856 19L15.6214 16.6643C15.8214 16.8143 15.9999 16.9285 16.1499 17.0071C16.1856 17.0214 16.2285 17.0428 16.2785 17.0643C16.3356 17.0857 16.3928 17.0928 16.4571 17.0928C16.5785 17.0928 16.6714 17.05 16.7499 16.9714L17.2928 16.4357C17.4714 16.2571 17.6428 16.1214 17.8071 16.0357C17.9714 15.9357 18.1356 15.8857 18.3142 15.8857C18.4499 15.8857 18.5928 15.9143 18.7499 15.9785C18.9071 16.0428 19.0714 16.1357 19.2499 16.2571L21.6142 17.9357C21.7999 18.0643 21.9285 18.2143 22.0071 18.3928C22.0785 18.5714 22.1214 18.75 22.1214 18.95Z"
        fill="#2F2A23"
      />
    </svg>
  );
};
