export const SvgForest = () => {
  return (
    <svg
      width="120"
      height="132"
      viewBox="0 0 120 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_331_968)">
        <g clip-path="url(#clip1_331_968)">
          <path
            d="M60 132C93.1371 132 120 105.137 120 72C120 38.8629 93.1371 12 60 12C26.8629 12 0 38.8629 0 72C0 105.137 26.8629 132 60 132Z"
            fill="url(#paint0_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M119.963 74.1209C99.9219 72.6445 78.9017 73.1579 56.9028 75.6609C34.9648 78.1569 16.8062 82.5856 2.42691 88.9468C0.847424 83.5725 0 77.8849 0 71.9993C0 67.1477 0.575822 62.4307 1.66285 57.9127C6.35308 54.5935 12.7834 56.5616 17.741 61.2993C25.3999 68.6184 31.4314 72.2145 42.2881 61.2993C53.1448 50.3841 59.6346 52.9084 66.1017 61.2993C72.5687 69.6902 78.6641 67.5619 92.2739 65.5232C100.437 64.3004 109.679 66.5736 119.999 72.3426C119.996 72.9375 119.984 73.5303 119.963 74.1209Z"
            fill="url(#paint1_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.0100098 73.1084C5.52163 66.4546 12.251 64.5751 20.1979 67.47C36.2962 73.3341 37.6982 72.1406 44.5134 67.47C51.3286 62.7993 51.0374 59.7721 60.0003 65.162C68.9631 70.5518 75.9245 73.491 83.587 70.8515C91.2495 68.2119 100.572 63.2548 109.46 73.2217C89.0857 72.5391 71.4148 73.4937 56.4482 76.0855C39.3945 79.0387 15.1697 89.8399 4.50936 94.8618C1.73614 88.138 0.149408 80.8 0.0100098 73.1084Z"
            fill="url(#paint2_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.42798 88.9518C31.2285 74.6204 70.4098 69.5885 119.972 73.856C118.991 106.135 92.5161 132 59.9999 132C32.7497 132 9.74258 113.834 2.42798 88.9518Z"
            fill="url(#paint3_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.38379 88.7995C26.9115 75.8513 60.2072 70.5154 102.271 72.7918C95.8706 72.7918 71.43 72.7918 56.4067 80.6484C41.3835 88.505 44.7798 93.5178 55.4535 97.9015C66.1271 102.285 59.5847 109.186 55.4535 109.186C52.6993 109.186 47.7085 108.323 40.481 106.598C32.5302 105.171 23.6012 106.358 13.6939 110.157C8.58119 103.959 4.68857 96.7173 2.38379 88.7995Z"
            fill="url(#paint4_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.104 109.43C26.3658 103.777 38.7264 103.139 50.1858 107.514C74.1826 116.678 86.3292 99.651 92.4159 85.5383C97.1021 74.6729 112.944 72.9996 119.987 73.2692C119.312 105.82 92.7132 132 60.0002 132C41.0201 132 24.0985 123.187 13.104 109.43H13.104Z"
            fill="url(#paint5_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M49.441 131.074C49.3548 131.059 49.2687 131.043 49.1826 131.028V131.016C49.2691 131.036 49.3552 131.055 49.441 131.074H49.441Z"
            fill="url(#paint6_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M57.2583 131.938C60.069 131.812 62.1432 131.037 63.4809 129.613C67.0787 125.784 58.2629 116.684 71.9528 115.93C85.6426 115.176 102.784 116.864 97.4746 105.589C92.1686 94.3211 98.6322 73.3659 119.985 73.3413C119.272 105.859 92.6888 132 60.0001 132C59.0812 132 58.1671 131.979 57.2583 131.938Z"
            fill="url(#paint7_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M55.2688 54.2311C56.7235 49.8949 58.8334 45.9691 60 42.8043C62.8489 35.0754 69.4502 33.7873 69.4502 27.1569C69.4502 20.5264 75.197 19.1765 78.2939 13.4C81.3908 7.62346 84.2743 13.9762 87.63 19.1765C90.9857 24.3767 94.6769 23.82 94.6769 33.7873C94.6769 43.7547 101.619 43.8624 105.735 52.9158C107.987 57.8676 111.167 63.5995 115.275 70.1116C106.838 66.1533 99.1712 64.6908 92.2739 65.7239C78.6641 67.7625 72.5687 69.8909 66.1016 61.5C62.7065 57.0948 59.3051 54.3066 55.2688 54.2311Z"
            fill="url(#paint8_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M87.6301 19.1763C90.9858 24.3765 94.677 23.8197 94.677 33.7871C94.677 43.7545 101.619 43.8622 105.735 52.9156C107.987 57.8674 111.167 63.5993 115.276 70.1113C106.839 66.153 99.1713 64.6905 92.2739 65.7236C78.6642 67.7623 75.0001 57.6412 79.6439 53.6506C84.2878 49.66 93.1105 43.3295 89.1745 39.0793C85.2385 34.8291 86.0857 31.5192 87.6301 27.9278C88.2794 26.4085 88.6041 24.9438 88.6041 23.5337C88.6041 21.4185 87.6301 19.1763 87.6301 19.1763Z"
            fill="url(#paint9_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M93.3526 65.5835C92.991 65.6236 92.6314 65.6704 92.2739 65.7239C78.6641 67.7625 72.5687 69.8909 66.1017 61.5C59.6346 53.1091 53.1448 50.5848 42.2881 61.5C31.4314 72.4152 25.3999 68.8191 17.741 61.5C15.3599 59.2244 12.639 57.5879 9.93433 56.8692C11.0987 54.6663 12.2335 52.3515 12.8222 50.0011C14.3294 43.9833 14.0233 40.4399 18.8633 37.9782C23.7033 35.5165 25.1667 33.2811 25.1667 26.212C25.1667 19.1429 33.7848 19.3129 32.7597 12.4859C31.7346 5.65899 36.4105 5.82192 40.7415 1.56166C45.0724 -2.69861 50.0757 9.12705 54.0228 12.4859C57.9699 15.8448 54.7814 18.9357 58.3912 21.5735C62.0011 24.2112 62.0774 24.079 63.3669 29.8296C64.2266 33.6633 66.3978 36.3795 69.8804 37.9782C71.5747 38.7066 74.2388 39.0709 77.8724 39.0709C83.3229 39.0709 82.3123 44.6802 82.3123 50.0011C82.3123 55.322 87.6456 57.7843 91.6518 62.7727C92.3946 63.6977 92.9615 64.6346 93.3526 65.5835Z"
            fill="url(#paint10_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M36.8623 7.84106C33.6946 11.4591 43.8334 16.3751 38.9887 24.4339C34.144 32.4926 51.1073 37.978 41.8818 45.9558C35.7315 51.2743 35.8669 56.4556 42.2881 61.4999C31.4314 72.4151 25.3999 68.819 17.741 61.4999C15.3599 59.2243 12.639 57.5877 9.93433 56.869C11.0987 54.6661 12.2335 52.3513 12.8222 50.0009C14.3294 43.9831 14.0233 40.4397 18.8633 37.978C23.7033 35.5163 25.1667 33.2809 25.1667 26.2118C25.1667 19.1427 33.7848 19.3127 32.7597 12.4858C31.7346 5.65886 36.4105 5.82178 40.7415 1.56152C40.2672 3.33584 38.9741 5.42902 36.8623 7.84106Z"
            fill="url(#paint11_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M25.6278 72.9485C21.6748 73.7398 19.4828 72.8485 19.052 70.2746C18.4056 66.4137 24.0558 64.3558 21.2307 60.3405C18.4056 56.3251 23.5011 52.979 22.9376 49.1676C22.3741 45.3563 24.143 43.6262 27.9038 51.9546C31.6645 60.2831 25.9264 59.4114 29.4558 65.4145C31.8087 69.4166 30.5327 71.9279 25.6278 72.9485Z"
            fill="url(#paint12_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24.8743 55.5439C26.2667 57.9493 25.0821 59.8778 24.6489 61.316C24.2157 62.7541 24.5216 65.1005 24.8743 66.2216C25.227 67.3426 24.795 69.9104 24.6489 70.8631C24.5028 71.8157 24.9622 73.0724 24.6489 73.1155C21.3115 73.5748 19.4459 72.628 19.052 70.275C18.4056 66.4141 24.0558 64.3562 21.2307 60.3408C18.4056 56.3255 23.5012 52.9793 22.9376 49.168C23.3005 51.815 23.9461 53.9403 24.8743 55.5439Z"
            fill="url(#paint13_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.9578 80.3295C24.2532 74.7822 24.4389 71.4328 23.5151 70.2812C22.1293 68.5539 21.9351 66.8464 22.9578 68.3237C23.9805 69.801 25.0499 70.2384 24.3667 66.0885C23.6836 61.9386 25.8116 57.5075 25.4484 59.1786C25.0852 60.8496 24.2464 63.6098 25.1181 66.0885C25.9898 68.5672 23.8226 70.8563 26.7737 69.7111C29.7249 68.566 27.8456 69.7252 26.4703 70.8382C25.0951 71.9512 25.0687 71.9608 25.6299 74.6908C26.004 76.5108 26.124 78.0866 25.9898 79.4183L22.9578 80.3295Z"
            fill="url(#paint14_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M49.628 70.0348C47.1062 70.5409 45.7079 69.9709 45.433 68.3248C45.0207 65.8556 48.6252 64.5395 46.823 61.9715C45.0207 59.4036 48.2713 57.2636 47.9118 54.8261C47.5523 52.3886 48.6808 51.2822 51.0799 56.6085C53.479 61.9348 49.8185 61.3774 52.07 65.2166C53.571 67.776 52.757 69.3821 49.628 70.0348Z"
            fill="url(#paint15_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M49.1473 58.9038C50.0355 60.4422 49.2798 61.6755 49.0035 62.5952C48.7272 63.515 48.9223 65.0156 49.1473 65.7325C49.3723 66.4495 49.0967 68.0917 49.0035 68.7009C48.9103 69.3102 49.2034 70.1139 49.0035 70.1414C46.8745 70.4352 45.6843 69.8297 45.433 68.3248C45.0207 65.8557 48.6251 64.5396 46.823 61.9716C45.0207 59.4036 48.2713 57.2637 47.9118 54.8262C48.1433 56.519 48.5552 57.8782 49.1473 58.9038Z"
            fill="url(#paint16_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M47.9251 74.7551C48.7515 71.2074 48.87 69.0653 48.2806 68.3289C47.3966 67.2242 47.2727 66.1322 47.9251 67.077C48.5776 68.0218 49.2597 68.3015 48.8239 65.6475C48.3881 62.9935 49.7456 60.1597 49.514 61.2284C49.2823 62.2971 48.7471 64.0623 49.3032 65.6475C49.8593 67.2327 48.4768 68.6967 50.3594 67.9643C52.242 67.2319 51.0432 67.9733 50.1659 68.6851C49.2886 69.3969 49.2717 69.403 49.6297 71.1489C49.8684 72.3128 49.9503 73.4195 49.8755 74.4689L47.9251 74.7551Z"
            fill="url(#paint17_linear_331_968)"
          />
          <path
            d="M44.8334 75.1104L45.0111 75.2022C40.4012 84.1226 37.3408 89.6823 35.8265 91.8865L35.6616 91.7733C37.1694 89.5786 40.2278 84.0225 44.8334 75.1104Z"
            fill="#123874"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M66.0747 88.8725L68.4224 89.5624C66.4156 90.4811 64.9936 91.4419 64.1565 92.4447C59.8393 93.0801 56.1479 93.954 53.0823 95.0665C45.6551 94.3598 40.5279 92.9034 37.7007 90.6973L44.7028 81.1655L66.0747 88.8725Z"
            fill="url(#paint18_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M63.7125 91.4545C65.5151 90.1862 67.0934 89.552 68.4472 89.552C65.014 85.5637 62.0028 81.1798 59.4135 76.4004L51.1602 80.1704L57.715 90.6376L60.6518 89.904H62.7279L63.7125 91.4545Z"
            fill="url(#paint19_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M37.6992 90.5906C41.3777 92.5606 46.4684 93.7468 52.9713 94.1492C56.159 92.8073 58.977 92.1364 61.4253 92.1364C60.2123 88.6969 59.5341 83.425 59.3907 76.3204C55.9033 76.4483 53.0778 76.4483 50.9142 76.3204C48.7506 76.1924 46.7418 75.7936 44.8878 75.124C43.6768 78.6953 42.4708 81.6033 41.2698 83.848C40.0688 86.0926 38.8786 88.3402 37.6992 90.5906Z"
            fill="url(#paint20_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M37.6992 90.5906C41.3777 92.5606 46.4684 93.7468 52.9713 94.1492C55.5695 88.8114 57.7093 82.8685 59.3907 76.3204C55.9033 76.4483 53.0778 76.4483 50.9142 76.3204C48.7506 76.1924 46.7418 75.7936 44.8878 75.124C43.6768 78.6953 42.4708 81.6033 41.2698 83.848C40.0688 86.0926 38.8786 88.3402 37.6992 90.5906Z"
            fill="url(#paint21_linear_331_968)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M63.7123 91.4545C65.5149 90.1862 67.0931 89.552 68.447 89.552C65.0138 85.5637 62.0026 81.1798 59.4133 76.4004C59.8979 80.0076 60.3419 82.6327 60.7451 84.2757C61.0784 85.6335 61.7392 87.5096 62.7277 89.904L63.7123 91.4545Z"
            fill="url(#paint22_linear_331_968)"
          />
          <path
            d="M53.8881 96.3805L53.7078 96.2941C54.9936 93.6104 56.8689 87.0133 59.3295 76.5122L59.3819 76.2886L59.5099 76.4794C63.5847 82.553 67.4386 87.0232 71.07 89.8896L70.9461 90.0466C67.3343 87.1957 63.5101 82.7739 59.4718 76.7812C57.0334 87.1678 55.1735 93.6979 53.8881 96.3805Z"
            fill="#123874"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M71.5195 89.3366L70.9496 90.0465L70.9089 89.9373L71.2348 89.2021L71.5195 89.3366Z"
            fill="#123874"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M53.1265 96.1005L53.3178 95.8223L53.9261 96.2696L53.8855 96.3788L53.1265 96.1005Z"
            fill="#123874"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M35.0869 91.5282L35.2696 91.252L35.8607 91.7812L35.827 91.8834L35.0869 91.5282Z"
            fill="#123874"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_331_968"
          x1="80.6522"
          y1="71.1787"
          x2="99.9791"
          y2="23.5482"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D5EDF3" />
          <stop offset="1" stop-color="#A2DBD8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_331_968"
          x1="73.6393"
          y1="63.2427"
          x2="42.267"
          y2="88.5601"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2FAC6D" />
          <stop offset="1" stop-color="#007846" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_331_968"
          x1="103.241"
          y1="64.8694"
          x2="29.0587"
          y2="90.9283"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2FAC6D" />
          <stop offset="1" stop-color="#007846" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_331_968"
          x1="106.079"
          y1="74.9661"
          x2="41.0249"
          y2="108.601"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#68C179" />
          <stop offset="1" stop-color="#25A062" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_331_968"
          x1="89.9107"
          y1="80.2579"
          x2="52.2307"
          y2="98.1072"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#68C179" />
          <stop offset="1" stop-color="#25A062" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_331_968"
          x1="51.2803"
          y1="88.796"
          x2="46.9784"
          y2="110.803"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#77EBFF" />
          <stop offset="1" stop-color="#4D86DE" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_331_968"
          x1="49.3088"
          y1="131.008"
          x2="49.2877"
          y2="131.096"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#91F6FF" />
          <stop offset="1" stop-color="#4D86DE" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_331_968"
          x1="77.1097"
          y1="59.1154"
          x2="31.182"
          y2="126.066"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#91F6FF" />
          <stop offset="1" stop-color="#4D86DE" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_331_968"
          x1="62.5971"
          y1="12.7941"
          x2="46.2292"
          y2="61.0092"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#30A0DE" />
          <stop offset="1" stop-color="#194383" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_331_968"
          x1="132.211"
          y1="101.233"
          x2="161.999"
          y2="-12.1059"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#30A0DE" />
          <stop offset="1" stop-color="#194383" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_331_968"
          x1="26.0925"
          y1="12.2088"
          x2="14.343"
          y2="59.069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#30A0DE" />
          <stop offset="1" stop-color="#194383" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_331_968"
          x1="13.5684"
          y1="16.8061"
          x2="1.01527"
          y2="74.7713"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#30A0DE" />
          <stop offset="1" stop-color="#194383" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_331_968"
          x1="20.3898"
          y1="46"
          x2="20.3898"
          y2="66.7031"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#68C179" />
          <stop offset="1" stop-color="#1FA063" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_331_968"
          x1="19.2832"
          y1="50.5911"
          x2="17.5771"
          y2="72.8742"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#68C179" />
          <stop offset="1" stop-color="#1FA063" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_331_968"
          x1="27.5571"
          y1="83.609"
          x2="28.1248"
          y2="67.3763"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#006239" />
          <stop offset="1" stop-color="#007846" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_331_968"
          x1="46.2865"
          y1="52.8003"
          x2="46.2865"
          y2="66.0407"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#68C179" />
          <stop offset="1" stop-color="#1FA063" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_331_968"
          x1="45.5804"
          y1="55.737"
          x2="44.492"
          y2="69.9882"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#68C179" />
          <stop offset="1" stop-color="#1FA063" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_331_968"
          x1="51.525"
          y1="83.5018"
          x2="52.4831"
          y2="68.0653"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#006239" />
          <stop offset="1" stop-color="#008D52" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_331_968"
          x1="98.5134"
          y1="75.2103"
          x2="46.4192"
          y2="84.8173"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#68C179" />
          <stop offset="1" stop-color="#139553" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_331_968"
          x1="55.8362"
          y1="78.7111"
          x2="56.6969"
          y2="85.6544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9BB49" />
          <stop offset="1" stop-color="#CD6709" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_331_968"
          x1="40.4026"
          y1="78.7878"
          x2="44.7293"
          y2="96.9992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9BB49" />
          <stop offset="1" stop-color="#F08523" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_331_968"
          x1="42.6459"
          y1="76.6009"
          x2="41.3809"
          y2="86.9942"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9BB49" />
          <stop offset="1" stop-color="#F08523" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_331_968"
          x1="60.7365"
          y1="76.1958"
          x2="60.0565"
          y2="86.812"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9BB49" />
          <stop offset="1" stop-color="#F08523" />
        </linearGradient>
        <clipPath id="clip0_331_968">
          <rect
            width="120"
            height="131.353"
            fill="white"
            transform="translate(0 0.647461)"
          />
        </clipPath>
        <clipPath id="clip1_331_968">
          <rect
            width="120"
            height="131.353"
            fill="white"
            transform="translate(0 0.647461)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
