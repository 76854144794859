import { toursDay, toursFetching, toursFetchingError } from "../Reducers/ToursSlice"
import { AppDispatch } from "../store"
import { $api } from "../../componets/api"
import { toursFetchingSuccess } from "../Reducers/ToursSlice"

export const fetchingTours = ()=>{
    return async (dispatch: AppDispatch)=>{
        try {
            dispatch(toursFetching())
            const response = await $api.get(`tours/`) 
            dispatch(toursFetchingSuccess(response.data))
            
        }catch(error: any){
            dispatch(toursFetchingError(error.message))
        }
    }
}

export const filterTours = (btn: any)=>{
    return async (dispatch: AppDispatch)=>{
      dispatch(toursDay(btn))
    }
}