import axios from "axios"
import { fetchingVisa, fetchingVisaError, fetchingVisaSuccess } from "../Reducers/Visa"
import { AppDispatch } from "../store"
import { $api } from "../../componets/api"

export const fetchingVisaFn = () =>{
    return async(dispatch: AppDispatch) => {
        try{
            dispatch(fetchingVisa())

            const response = await $api.get(`country_visa/list/`) 
            dispatch(fetchingVisaSuccess(response.data))
        }catch(error: any){
            dispatch(fetchingVisaError(error.message))
        }
    }
}