export const Svg_filter_select = () => {
  return (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.67456 0.81952C9.24065 0.393493 8.53713 0.393493 8.10322 0.81952L5 3.8663L1.89679 0.81952C1.46287 0.393493 0.759354 0.393493 0.325438 0.81952C-0.108479 1.24555 -0.108479 1.93627 0.325438 2.36229L4.21434 6.18048C4.64824 6.60651 5.35175 6.60651 5.78567 6.18048L9.67456 2.36229C10.1085 1.93627 10.1085 1.24555 9.67456 0.81952Z"
        fill="#2F2A23"
      />
    </svg>
  );
};
