import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { IFAQdetail } from "../../types/FAQ"


interface IFAQ {
    FAQ : IFAQdetail[],
    loader: boolean,
    error: null | string
}


const initialState:IFAQ = {
    FAQ : [],
    loader : false,
    error : ''
}

export const FAQslice = createSlice({
    name : 'FAQ',
    initialState,
    reducers:{
        fetchingFAQ (state) {
            state.loader = true
        },
        fetchingFAQSuccess (state, action: PayloadAction<IFAQdetail[]>) {
            state.loader = false
            state.FAQ = action.payload
            state.error = ''
        },
        fetchingFAQError (state, action: PayloadAction<string>) {
            state.loader = false
            state.error = action.payload
            state.FAQ = []
        },
    }
})


export default FAQslice.reducer
export const {fetchingFAQ, fetchingFAQSuccess, fetchingFAQError}  = FAQslice.actions