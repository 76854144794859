import {
  Box,
  Container,
  Heading,
  Image,
} from "@chakra-ui/react";
import FormBooking from "../ticketsPost.tsx/postBooking";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";


interface IdetailToursProps {
  detailTours : any
}
export const TourBooking = ({detailTours}: IdetailToursProps) => {

  return (
    <Container maxW="1300px" bg={"#F9F9F9"} position="relative">
     
        <Box bg={"#F9F9F9"} >
          {
            detailTours.title ? 
            <Heading
            fontFamily="SF Pro Text "
            color="#2F2A23"
            fontSize={{
              xl: "48px",
              lg: "48px",
              md: "48px",
              sm: "36px",
              base: "36px",
            }}
            fontWeight="700"
            mb="30px"
            bg={"#F9F9F9"}
          >
            {detailTours.title}
          </Heading>
          :
          <SkeletonTheme
          baseColor="rgb(239, 239, 239)"
          highlightColor="#fcfcfd"
          width="285px"
          height="34px"
        >
          <p>
            <Skeleton />
          </p>
        </SkeletonTheme> 
          }
          {
            detailTours.main_image ? 
            <Image
            src={detailTours.main_image}
            width={"100%"}
            height="420px"
            borderRadius={"20px"}
            objectFit="cover"
            alt=""
          />
          :
          <SkeletonTheme
          baseColor="rgb(239, 239, 239)"
          highlightColor="#fcfcfd"
          width= '100%'
          height="450px"
        >
          <p>
            <Skeleton />
          </p>
        </SkeletonTheme> 
          }
       
        
        </Box>

      <Box
        display={{
          xl: "flex",
          lg: "flex",
          md: "flex",
          sm: "block",
          base: "block",
        }}
        justifyContent="space-between"
        top={{ xl: "84%", lg: "84%", md: "84%", sm: "77%", base: "77%" }}
        position="absolute"
        left={{ xl: "86px", lg: "86px", md: "86px", sm: "26px", base: "26px" }}
        borderRadius="24px"
        backdropBlur="blur(10.8731px)"
        boxShadow=" 0px 40px 64px -32px rgba(15, 15, 15, 0.1)"
        
      >
          <FormBooking/>
      </Box>
    </Container>
  );
};
