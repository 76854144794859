import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { MyData } from "../../types/MyData";




export const postBooking = createAsyncThunk(
    'mySlice/postData',
    async (values: MyData) => {
      const response = await axios.post<MyData>(
        'https://api.papatruck.org/book-ticket/',
        values
      );
      return response.data;
    }
  );